import Enum from './enum'

/**
 * 曜日
 */
class Days extends Enum {
    static SUNDAY = 0; // 日
    static MONDAY = 1; // 月
    static TUESDAY = 2; // 火
    static WEDNESDAY = 3; // 水
    static THURSDAY = 4; // 木
    static FRIDAY = 5; // 金
    static SATURDAY = 6; // 土

    static values() {
        return {
            [this.SUNDAY]: '日',
            [this.MONDAY]: '月',
            [this.TUESDAY]: '火',
            [this.WEDNESDAY]: '水',
            [this.THURSDAY]: '木',
            [this.FRIDAY]: '金',
            [this.SATURDAY]: '土',
        }
    }
}

export default Days;