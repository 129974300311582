<template>
    <transition name="fade">
        <div>
            <div class="modal-backdrop show"></div>
            <div class="modal" tabindex="-1" style="display:block">
                <div class="modal-dialog modal-fullscreen">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">
                                {{ modal_title }}
                                <template v-if="modal_title_notice"><span class="text-danger">{{ modal_title_notice }}</span></template>
                            </h5>
                            <button type="button" class="btn-close" @click="cancelEdit"></button>
                        </div>
                        <inline-loader v-if="modal_loading"></inline-loader>
                        <template v-else>
                            <div class="modal-body">
                                <div class="text-end">
                                    <h1 v-if="parseInt(schedule.schedule_status, 10) === ScheduleStatus.FINISHED">
                                        <span class="badge rounded-pill bg-success"><i class="bi bi-check-circle-fill"></i> 撮影完了</span>
                                    </h1>
                                    <h1 v-if="parseInt(schedule.schedule_status, 10) === ScheduleStatus.POSTPONED">
                                        <span class="badge rounded-pill bg-warning"><i class="bi bi-skip-forward-fill"></i> 雨天延期</span>
                                    </h1>
                                    <h1 v-if="parseInt(schedule.schedule_status, 10) === ScheduleStatus.CANCELED">
                                        <span class="badge rounded-pill bg-danger"><i class="bi bi-dash-circle-fill"></i> 撮影キャンセル</span>
                                    </h1>
                                </div>
                                <form @submit.prevent="prepare" id="modal_form">
                                    <div class="form-group mb-3">
                                        <label>種類</label>
                                        <read-only
                                            v-if="parseInt(schedule.schedule_type, 10) === ScheduleType.SPARE"
                                            :text="ScheduleType.get(schedule.schedule_type)"
                                        ></read-only>
                                        <form-select
                                            v-else
                                            v-model="schedule.schedule_type"
                                            :options="schedule_types"
                                            empty_option="-- 選択 --"
                                            :required="true"
                                        ></form-select>
                                    </div>

                                    <div class="form-group mb-3">
                                            <label>店舗</label>
                                            <read-only
                                                v-if="parseInt(schedule.schedule_type, 10) === ScheduleType.SPARE"
                                                :text="schedule.studio.studio_name"
                                            ></read-only>
                                            <template v-else>
                                                <form-select
                                                    v-model="schedule.studio.studio_id"
                                                    :options="options_studio"
                                                    empty_option="-- 選択 --"
                                                    :required="true"
                                                ></form-select>
                                            </template>
                                        </div>

                                    <!-- 本予定確認ボタン -->
                                    <div class="mb-3 text-end"
                                        v-if="parseInt(schedule.schedule_type, 10) === ScheduleType.SPARE"
                                    >
                                        <button-exec
                                            text="本予定確認"
                                            color="btn-outline-primary"
                                            class="align-self-end"
                                            @click="openParentScheduleModal(schedule.schedule_id)"
                                        ></button-exec>
                                    </div>

                                    <div class="form-group mb-3">
                                        <label v-if="parseInt(schedule.schedule_type, 10) === ScheduleType.EVENT_SHOOTING">ジャンル</label>
                                        <label v-else>学校名</label>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <read-only
                                                v-if="isDeletedSchool"
                                                :text="schedule.school.school_name"
                                            ></read-only>
                                            <template v-else>
                                                <read-only
                                                    v-if="parseInt(schedule.schedule_type, 10) === ScheduleType.SPARE"
                                                    :text="findSchoolNameById(schedule.school.school_id)"
                                                ></read-only>
                                                <template v-else>
                                                    <!-- 学校名表示 -->
                                                    <div class="col-17 d-flex align-items-center">
                                                        <div class="school-name">{{ schedule.school.school_name ?? '未選択' }}</div>
                                                        <div class="reset-input" style="cursor: pointer;" @click="resetSchoolInput"><i class="bi bi-eraser-fill"></i></div>
                                                    </div>
                                                    <button-exec
                                                        text="学校選択"
                                                        size="btn-sm"
                                                        color="btn-outline-primary"
                                                        @click="openSchoolSelectSearchModal"
                                                        class="col-6"
                                                        style="padding: 0.45rem;"
                                                    ></button-exec>
                                                </template>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="form-group mb-2">
                                        <label v-if="parseInt(schedule.schedule_type, 10) === ScheduleType.EVENT_SHOOTING">イベント名</label>
                                        <label v-else>スケジュール名</label>
                                        <inline-loader v-if="event_loading"></inline-loader>
                                        <template v-else>
                                            <read-only
                                                v-if="isDeletedSchool"
                                                :text="schedule.event.event_name"
                                            ></read-only>
                                            <template v-else>
                                                <read-only
                                                    v-if="parseInt(schedule.schedule_type, 10) === ScheduleType.SPARE"
                                                    :text="findEventNameById(schedule.event.event_id)"
                                                ></read-only>
                                                <form-select
                                                    v-else
                                                    v-model="schedule.event.event_id"
                                                    :options="options_event"
                                                    empty_option="-- 選択 --"
                                                    :required="!schedule.schedule_name"
                                                ></form-select>
                                            </template>
                                        </template>
                                    </div>
                                    <div class="form-group d-flex justify-content-end mb-3">
                                        <template v-if="parseInt(schedule.schedule_type, 10) === ScheduleType.SPARE">
                                            <template v-if="$helper.isNud(schedule.schedule_name)"></template>
                                            <read-only
                                                v-else
                                                :text="schedule.schedule_name"
                                            ></read-only>
                                        </template>
                                        <form-input
                                            v-else
                                            v-model="schedule.schedule_name"
                                            placeholder="自由入力"
                                            :required="!schedule.event.event_id"
                                        ></form-input>
                                    </div>
                                    <div class="mb-3">
                                        <label>日時</label>
                                        <div class="row justify-content-between mb-2">
                                            <div class="col">
                                                <read-only
                                                    v-if="parseInt(schedule.schedule_type, 10) === ScheduleType.SPARE"
                                                    :text="schedule.schedule_date_for_input"
                                                ></read-only>
                                                <form-input-date
                                                    v-else
                                                    v-model="schedule.schedule_date_for_input"
                                                    :required="true"
                                                    @change="fetchScheduleCars()"
                                                ></form-input-date>
                                            </div>
                                            <template v-if="
                                                parseInt(schedule.is_unfixed, 10) === IsUnfixed.UNFIXED
                                                && schedule.schedule_date_for_input === display_end_date
                                            ">
                                                <div class="col">
                                                    <read-only
                                                        v-if="parseInt(schedule.schedule_type, 10) === ScheduleType.SPARE"
                                                        :text="UnfixedType.get(schedule.unfixed_type)"
                                                    ></read-only>
                                                    <form-select
                                                        v-else
                                                        v-model="schedule.unfixed_type"
                                                        :options="unfixed_types"
                                                        empty_option="-- 選択 --"
                                                        :required="parseInt(schedule.is_unfixed, 10) === IsUnfixed.UNFIXED"
                                                    ></form-select>
                                                </div>
                                            </template>
                                            <div
                                                class="col input-group"
                                                v-else-if="parseInt(schedule.is_unfixed, 10) === IsUnfixed.UNFIXED && schedule.schedule_date_for_input !== display_end_date">
                                            </div>
                                            <div class="col input-group" v-else>
                                                <read-only
                                                    v-if="parseInt(schedule.schedule_type, 10) === ScheduleType.SPARE"
                                                    :text="schedule.hour_from"
                                                ></read-only>
                                                <form-input-number
                                                    v-else
                                                    v-model="schedule.hour_from"
                                                    min="0"
                                                    max="24"
                                                    step="1"
                                                    :required="parseInt(schedule.is_unfixed, 10) !== IsUnfixed.UNFIXED"
                                                ></form-input-number>
                                                <span class="input-group-text">:</span>
                                                <read-only
                                                    v-if="parseInt(schedule.schedule_type, 10) === ScheduleType.SPARE"
                                                    :text="schedule.minute_from"
                                                ></read-only>
                                                <form-input-number
                                                    v-else
                                                    v-model="schedule.minute_from"
                                                    min="0"
                                                    max="59"
                                                    step="1"
                                                    :required="parseInt(schedule.is_unfixed, 10) !== IsUnfixed.UNFIXED"
                                                ></form-input-number>
                                            </div>
                                        </div>
                                        <div class="row justify-content-between mb-2">
                                            <div class="col">
                                                <read-only
                                                    v-if="parseInt(schedule.schedule_type, 10) === ScheduleType.SPARE"
                                                    :text="display_end_date"
                                                ></read-only>
                                                <form-input-date
                                                    v-else
                                                    v-model="display_end_date"
                                                    :required="true"
                                                    @change="setCarryOutInRainType"
                                                ></form-input-date>
                                            </div>
                                            <div class="col input-group">
                                                <template  v-if="parseInt(schedule.is_unfixed, 10) !== IsUnfixed.UNFIXED && schedule.schedule_date_for_input === display_end_date">
                                                    <read-only
                                                        v-if="parseInt(schedule.schedule_type, 10) === ScheduleType.SPARE"
                                                        :text="schedule.hour_to"
                                                    ></read-only>
                                                    <form-input-number
                                                        v-else
                                                        v-model="schedule.hour_to"
                                                        min="0"
                                                        max="24"
                                                        step="1"
                                                        :required="parseInt(schedule.is_unfixed, 10) !== IsUnfixed.UNFIXED"
                                                    ></form-input-number>
                                                    <span class="input-group-text">:</span>
                                                    <read-only
                                                        v-if="parseInt(schedule.schedule_type, 10) === ScheduleType.SPARE"
                                                        :text="schedule.minute_to"
                                                    ></read-only>
                                                    <form-input-number
                                                        v-else
                                                        v-model="schedule.minute_to"
                                                        min="0"
                                                        max="59"
                                                        step="1"
                                                        :required="parseInt(schedule.is_unfixed, 10) !== IsUnfixed.UNFIXED"
                                                    ></form-input-number>
                                                </template>
                                                <template v-if="parseInt(schedule.is_unfixed, 10) !== IsUnfixed.UNFIXED && schedule.schedule_date_for_input !== display_end_date">
                                                    <div class="input-group">
                                                        <read-only
                                                            v-if="parseInt(schedule.schedule_type, 10) === ScheduleType.SPARE"
                                                            :text="schedule.end_hour"
                                                        ></read-only>
                                                        <form-input-number
                                                            v-else
                                                            v-model="schedule.end_hour"
                                                            min="0"
                                                            max="24"
                                                            step="1"
                                                            :required="isRequiredSeveralDaysTime"
                                                        ></form-input-number>
                                                        <span class="input-group-text">:</span>
                                                        <read-only
                                                            v-if="parseInt(schedule.schedule_type, 10) === ScheduleType.SPARE"
                                                            :text="schedule.end_minute"
                                                        ></read-only>
                                                        <form-input-number
                                                            v-else
                                                            v-model="schedule.end_minute"
                                                            min="0"
                                                            max="59"
                                                            step="1"
                                                            :required="isRequiredSeveralDaysTime"
                                                        ></form-input-number>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                        <div class=" d-flex justify-content-end  mb-2">
                                            <form-checkbox
                                                v-model="schedule.is_unfixed"
                                                option_label="時間未定"
                                                class="col-8"
                                                :disabled="parseInt(schedule.schedule_type, 10) === ScheduleType.SPARE"
                                            ></form-checkbox>
                                        </div>
                                    </div>

                                    <template
                                        v-if="
                                            parseInt(schedule.schedule_type, 10) !== ScheduleType.MEETING &&
                                            parseInt(schedule.schedule_type, 10) !== ScheduleType.OTHERS
                                        "
                                    >
                                        <div class="mb-3">
                                            <div class="form-group mb-2">
                                                <label>雨天時対応</label>
                                                <form-select
                                                    v-model="schedule.rain_type"
                                                    :options="schedule.schedule_date_for_input !== display_end_date ? rain_types_except_postpone : rain_types"
                                                    empty_option="-- 選択 --"
                                                    :required="true"
                                                ></form-select>
                                            </div>
                                            <template
                                                v-if="
                                                    parseInt(schedule.rain_type, 10) === RainType.POSTPONE &&
                                                    schedule.schedule_date_for_input === display_end_date
                                                "
                                            >
                                                <div class="row justify-content-between mb-2">
                                                    <div class="col">
                                                        <form-input-date
                                                            v-model="schedule.spare_schedule.schedule_date_for_input"
                                                        ></form-input-date>
                                                    </div>
                                                    <template v-if="parseInt(schedule.spare_schedule.is_unfixed, 10) === IsUnfixed.UNFIXED">
                                                        <div class="col">
                                                            <form-select
                                                                v-model="schedule.spare_schedule.unfixed_type"
                                                                :options="unfixed_types"
                                                                :required="
                                                                    schedule.rain_type === RainType.POSTPONE &&
                                                                    schedule.spare_schedule.schedule_date_for_input &&
                                                                    schedule.schedule_date_for_input === display_end_date
                                                                "
                                                                empty_option="-- 選択 --"
                                                            ></form-select>
                                                        </div>
                                                    </template>
                                                    <div class="col input-group" v-else>
                                                        <form-input-number
                                                            v-model="schedule.spare_schedule.hour_from"
                                                            min="0"
                                                            max="24"
                                                            step="1"
                                                            :required="isRequiredSpareTime"
                                                        ></form-input-number>
                                                        <span class="input-group-text">:</span>
                                                        <form-input-number
                                                            v-model="schedule.spare_schedule.minute_from"
                                                            min="0"
                                                            max="59"
                                                            step="1"
                                                            :required="isRequiredSpareTime"
                                                        ></form-input-number>
                                                    </div>
                                                </div>
                                                <div class="row justify-content-between mb-2">
                                                    <div class="col"></div>
                                                    <div class="col input-group">
                                                        <template  v-if="parseInt(schedule.spare_schedule.is_unfixed, 10) !== IsUnfixed.UNFIXED">
                                                            <form-input-number
                                                                v-model="schedule.spare_schedule.hour_to"
                                                                min="0"
                                                                max="24"
                                                                step="1"
                                                                :required="isRequiredSpareTime"
                                                            ></form-input-number>
                                                            <span class="input-group-text">:</span>
                                                            <form-input-number
                                                                v-model="schedule.spare_schedule.minute_to"
                                                                min="0"
                                                                max="59"
                                                                step="1"
                                                                :required="isRequiredSpareTime"
                                                            ></form-input-number>
                                                        </template>
                                                    </div>
                                                </div>
                                                <div class=" d-flex justify-content-end  mb-2">
                                                    <form-checkbox
                                                        v-model="schedule.spare_schedule.is_unfixed"
                                                        option_label="時間未定"
                                                        class="col-8"
                                                    ></form-checkbox>
                                                </div>
                                            </template>
                                        </div>
                                        <hr>
                                        <div class="d-flex mt-2 mb-3">
                                            <div class="form-group col">
                                                <label class="col-form-label title">日程確認</label>
                                                <form-input-date
                                                    v-model="schedule.checked_date_for_input"
                                                    :required="
                                                        parseInt(schedule.schedule_type, 10) !== ScheduleType.MEETING &&
                                                        parseInt(schedule.schedule_type, 10) !== ScheduleType.OTHERS
                                                    "
                                                ></form-input-date>
                                            </div>
                                            <div class="col d-flex justify-content-end">
                                                <button-exec
                                                    text="今日"
                                                    color="btn-outline-primary"
                                                    @click="setCheckDate"
                                                    class="align-self-end"
                                                ></button-exec>
                                            </div>
                                        </div>
                                        <hr>
                                    </template>
                                    <hr v-if="
                                        parseInt(schedule.schedule_type, 10) === ScheduleType.MEETING ||
                                        parseInt(schedule.schedule_type, 10) === ScheduleType.OTHERS
                                    ">
                                    <div class="mb-3">
                                        <inline-loader v-if="schedule_photographer_loading"></inline-loader>
                                        <template v-else>
                                            <div class="mb-3 row">
                                                <div class="col-17 form-group">
                                                    <label class="col-form-label title">
                                                        <template  v-if="parseInt(schedule.schedule_type, 10) === ScheduleType.OTHERS">
                                                            社員
                                                        </template>
                                                        <template v-else>
                                                            カメラマン
                                                        </template>
                                                    </label>
                                                    <div class="input-group">
                                                        <form-input-number
                                                            v-model="schedule.photographer_number"
                                                            min="0"
                                                            step="1"
                                                            @change="supplySchedulePhotographers"
                                                        ></form-input-number>
                                                        <span class="input-group-text">名</span>
                                                    </div>
                                                </div>
                                                <div
                                                    v-if="parseInt(schedule.schedule_type, 10) === ScheduleType.OTHERS"
                                                    class="col-7 align-self-end"
                                                >
                                                    <button-exec
                                                        class="align-self-end"
                                                        text="全社員"
                                                        color="btn-outline-primary"
                                                        @click="setAllEmployees"
                                                    ></button-exec>
                                                </div>
                                            </div>
                                            <template v-for="schedule_photographer, index in schedule.photographers" :key="schedule_photographer">
                                                <div class="mb-2 p-2 border rounded">
                                                    <div class="col">
                                                        <read-only
                                                            v-if="schedule_photographer.is_locked === IsLocked.YES"
                                                            :text="findPhotographerNameById(schedule_photographer.photographer.photographer_id)"
                                                        ></read-only>
                                                        <form-select
                                                            v-else
                                                            v-model="schedule_photographer.photographer.photographer_id"
                                                            :options="parseInt(schedule.schedule_type, 10) === ScheduleType.OTHERS ? options_employee : options_photographer"
                                                            empty_option="-- 選択 --"
                                                            @change="resetSchedulePhotographerCheckbox(index)"
                                                        ></form-select>
                                                    </div>
                                                    <template
                                                        v-if="parseInt(schedule.schedule_type, 10) !== ScheduleType.OTHERS"
                                                    >
                                                        <div class="row justify-content-between mt-2">
                                                            <div class="col form-group">
                                                                <form-checkbox
                                                                    v-model="schedule_photographer.is_contacted"
                                                                    option_label="連絡済"
                                                                ></form-checkbox>
                                                            </div>
                                                            <div class="col form-group">
                                                                <form-checkbox
                                                                    v-model="schedule_photographer.ignore_alert"
                                                                    option_label="アラート無視"
                                                                ></form-checkbox>
                                                            </div>
                                                        </div>
                                                        <div class="row justify-content-between mt-4">
                                                            <div class="col align-self-center">
                                                                <div class="form-check form-switch">
                                                                    <label class="form-check-label">
                                                                        <i class="bi bi-lock" v-if="parseInt(schedule_photographer.is_locked, 10) === IsLocked.YES"></i>
                                                                        <i class="bi bi-unlock" v-else></i>
                                                                        <input
                                                                            type="checkbox"
                                                                            class="form-check-input"
                                                                            v-model="schedule_photographer.is_locked"
                                                                            :true-value="IsLocked.YES"
                                                                            :false-value="IsLocked.NO"
                                                                        >
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="col text-end">
                                                                <button-exec-delete
                                                                    v-if="schedule_photographer.is_locked === IsLocked.NO"
                                                                    text=""
                                                                    @click="removeSchedulePhotographer(index)"
                                                                ></button-exec-delete>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <div class="col mt-4 text-end">
                                                            <button-exec-delete
                                                                text=""
                                                                @click="removeSchedulePhotographer(index)"
                                                            ></button-exec-delete>
                                                        </div>
                                                    </template>
                                                </div>
                                            </template>
                                        </template>
                                    </div>

                                    <div class="mb-3">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th class="w-10">予約</th>
                                                    <th class="w-15">社用車</th>
                                                    <th>行き先（時間）</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template v-for="schedule_car_type in schedule_car_types" :key="schedule_car_type.car.car_id">
                                                    <tr>
                                                        <td>
                                                            <input
                                                                v-model="schedule.cars"
                                                                :value="schedule_car_type.car.car_id"
                                                                type="checkbox"
                                                                class="form-check-input"
                                                            >
                                                        </td>
                                                        <td>{{ schedule_car_type.car.car_name }}</td>
                                                        <td>
                                                            <template v-for="(schedule, index) in schedule_car_type.schedules" :key="schedule.schedule_id">
                                                                {{ schedule.schedule_name_shortened }}
                                                                <!-- 条件によって時間を表示 -->
                                                                <span v-if="parseInt(schedule.is_unfixed, 10) === IsUnfixed.UNFIXED">
                                                                    ({{ UnfixedType.get(schedule.unfixed_type) }})
                                                                </span>
                                                                <span v-else>
                                                                    ({{ schedule.schedule_time }})
                                                                </span>
                                                                <span v-if="index + 1 < schedule_car_type.schedules.length">,<br></span>
                                                            </template>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="mb-3">
                                        <div class="col-sm-5">
                                            <label class="col-form-label title">移動手段</label>
                                            <form-input
                                                v-model="schedule.transportation"
                                            ></form-input>
                                        </div>
                                    </div>

                                    <label class="col-form-label title">メモ</label>
                                    <ul class="list-group list-group-flush">
                                        <template v-for="memo in schedule.memos" :key="memo">
                                            <li class="list-group-item">
                                                <template v-if="editing_memos.some((editing_memo) => editing_memo.schedule_memo_id === memo.schedule_memo_id)">
                                                    <div class="text-start text-secondary mb-2" style="font-size: 10px">
                                                        {{ memo.memo_date_display }} {{ memo.employee.photographer_name }}
                                                    </div>
                                                    <form-textarea
                                                        v-model="memo.content"
                                                        placeholder="先方とのやりとりを記述する"
                                                    ></form-textarea>
                                                    <div class="d-flex justify-content-center align-items-center my-2">
                                                        <button-normal
                                                            class="mx-1"
                                                            size="btn-sm"
                                                            color="btn-secondary"
                                                            @click="toNormalMode(memo)"
                                                        ></button-normal>
                                                        <button-exec-update
                                                            text="保存"
                                                            class="mx-1"
                                                            icon=""
                                                            size="btn-sm"
                                                            type="button"
                                                            :disabled="$helper.isNud(memo.content)"
                                                            @click="updateMemo(memo)"
                                                        ></button-exec-update>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <div class="text-end text-secondary mb-2" style="font-size: 10px">
                                                        {{ memo.memo_date_display }} {{ memo.employee.photographer_name }}
                                                    </div>
                                                    <p class="list-group-item-content">{{ memo.content }}</p>
                                                    <div class="d-flex justify-content-end my-2">
                                                        <button-exec-delete
                                                            text=""
                                                            size="btn-sm"
                                                            @click="$refs.confirm_remove_memo.show(); delete_target_memo = memo;"
                                                        ></button-exec-delete>
                                                        <button-normal
                                                            text=""
                                                            icon="bi-pencil-square"
                                                            size="btn-sm"
                                                            class="ms-2"
                                                            @click="toEditMode(memo)"
                                                        ></button-normal>
                                                    </div>
                                                </template>
                                            </li>
                                        </template>
                                    </ul>
                                    <div class="col mb-1">
                                        <textarea
                                            v-model="additional_memo"
                                            class="form-control"
                                            ref="adjust_textarea"
                                            placeholder="先方とのやりとりを記述する"
                                            @keydown="adjustHeight"
                                        ></textarea>
                                    </div>

                                    <div v-if="drop_down" class="mb-3">
                                        <div class="mb-3">
                                            <label class="col-form-label title">備品</label>
                                            <template v-for="schedule_equipment in schedule_equipments" :key="schedule_equipment">
                                                <div class="mb-1 border rounded p-2">
                                                    <div class="row mb-2">
                                                        <div class="col-12">
                                                            <label class="col-form-label">{{ schedule_equipment.equipment.equipment_name }}</label>
                                                        </div>
                                                        <div
                                                            class="col-12"
                                                            v-if="!$helper.isNud(schedule_equipment.quantity) && schedule_equipment.quantity > 0"
                                                        >
                                                            <form-checkbox
                                                                v-model="schedule_equipment.ignore_alert"
                                                                option_label="アラート無視"
                                                            ></form-checkbox>
                                                        </div>
                                                    </div>
                                                    <div class="input-group">
                                                        <form-input-number
                                                            v-model="schedule_equipment.quantity"
                                                            min="0"
                                                            step="1"
                                                        ></form-input-number>
                                                        <span class="input-group-text">{{ schedule_equipment.equipment.unit }}</span>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>

                                        <div class="mb-3">
                                            <label class="col-form-label title">場所</label>
                                            <form-input
                                                v-model="schedule.schedule_place"
                                            ></form-input>
                                        </div>
                                        <div class="mb-3">
                                            <label class="col-form-label title">先方窓口</label>
                                            <form-input
                                                v-model="schedule.contact_person"
                                            ></form-input>
                                        </div>
                                        <div class="mb-3">
                                            <label class="col-form-label title">連絡先電話番号</label>
                                            <form-input-tel
                                                v-model="schedule.contact_tel"
                                                placeholder="連絡先電話番号"
                                            ></form-input-tel>
                                        </div>
                                        <div class="mb-3">
                                            <label class="col-form-label title">備考</label>
                                            <form-textarea
                                                v-model="schedule.note"
                                            ></form-textarea>
                                        </div>
                                        <div class="col">
                                            <div class="mb-3">
                                                <label class="btn btn-primary d-flex justify-content-center align-items-center" style="height: 100%;">
                                                    <input type="file" style="display: none;" multiple="multiple" @change="upload" >
                                                    <div><i class="bi bi-upload"></i> ファイルを選択...</div>
                                                </label>
                                            </div>
                                            <template v-for="schedule_file, index in schedule.files" :key="schedule_file">
                                                <div class="col mb-1 d-flex justify-content-between">
                                                    <a
                                                        v-if="
                                                            schedule_file.file.mime_type.slice(0, 5) === 'image' ||
                                                            schedule_file.file.mime_type === 'application/pdf'
                                                        "
                                                        :href="generateFileUrl(schedule_file)" target="_blank"
                                                    >
                                                        <i class="bi" :class="displayFileIcon(schedule_file)"></i>
                                                        {{ schedule_file.file.original_file_name }}
                                                    </a>
                                                    <a :href="generateFileUrl(schedule_file)" download v-else>
                                                        <i class="bi" :class="displayFileIcon(schedule_file)"></i>
                                                        {{ schedule_file.file.original_file_name }}
                                                    </a>
                                                    <button-exec-delete
                                                        text=""
                                                        @click="removeScheduleFile(index)"
                                                    ></button-exec-delete>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <button-exec
                                            :text="drop_down_text"
                                            :icon="icon"
                                            color="btn"
                                            @click="toggleDrop"
                                        ></button-exec>
                                    </div>
                                </form>
                            </div>
                            <template v-if="is_readonly">
                                <div class="modal-footer d-flex justify-content-end">
                                    <div>
                                        <button-exec
                                            text="閉じる"
                                            @click="close"
                                        ></button-exec>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="modal-footer d-flex justify-content-between">
                                    <div>
                                        <template
                                            v-if="
                                                !$helper.isNud(schedule.schedule_id) &&
                                                (schedule.schedule_type === ScheduleType.SHOOTING
                                                || schedule.schedule_type === ScheduleType.EVENT_SHOOTING) &&
                                                parseInt(schedule.schedule_status, 10) === ScheduleStatus.PENDING
                                            "
                                        >
                                            <button class="btn btn-outline-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                                ステータスを更新...
                                            </button>
                                            <div class="collapse" id="collapseExample">
                                                <div class="card card-body">
                                                    <template v-if="parseInt(schedule.rain_type, 10) === RainType.POSTPONE">
                                                        <button-exec
                                                            text="撮影完了..."
                                                            icon="bi-check-circle-fill"
                                                            color="btn-outline-success"
                                                            @click="$refs.confirm_finish.show()"
                                                            class="col mb-3"
                                                        ></button-exec>
                                                        <button-exec
                                                            text="雨天延期..."
                                                            icon="bi-skip-forward-fill"
                                                            color="btn-outline-warning"
                                                            @click="$refs.confirm_postpone.show()"
                                                            class="col mb-3"
                                                        ></button-exec>
                                                    </template>
                                                    <button-exec
                                                        text="撮影キャンセル..."
                                                        icon="bi-dash-circle-fill"
                                                        color="btn-outline-danger"
                                                        @click="$refs.confirm_cancel.show()"
                                                        class="col mb-3"
                                                    ></button-exec>
                                                    <button-exec
                                                        v-if="parseInt(schedule.schedule_type, 10) !== ScheduleType.SPARE"
                                                        text="削除..."
                                                        icon="bi-trash"
                                                        color="btn-link"
                                                        @click="$refs.confirm_remove.show()"
                                                        class="col text-danger"
                                                    ></button-exec>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <button-exec
                                                v-if="parseInt(schedule.schedule_type, 10) !== ScheduleType.SPARE"
                                                text="削除..."
                                                icon="bi-trash"
                                                color="btn-link"
                                                @click="$refs.confirm_remove.show()"
                                                class="col text-danger"
                                            ></button-exec>
                                        </template>
                                    </div>
                                    <div class="text-end">
                                        <button-exec-update
                                            text="保存"
                                            form="modal_form"
                                        ></button-exec-update>
                                    </div>
                                </div>
                            </template>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </transition>

    <confirm-dialog ref="confirm_finish" @ok="finishSchedule()">
        <p>このスケジュールを撮影完了にしてよろしいですか？</p>
    </confirm-dialog>
    <confirm-dialog ref="confirm_postpone" @ok="postponeSchedule()">
        <p>このスケジュールを雨天延期してよろしいですか？</p>
    </confirm-dialog>
    <confirm-dialog ref="confirm_cancel" @ok="cancelSchedule()">
        <p>このスケジュールを撮影キャンセルしてよろしいですか？</p>
    </confirm-dialog>
    <confirm-dialog ref="confirm_remove" @ok="removeSchedule()">
        <p>このスケジュールを削除してよろしいですか？</p>
    </confirm-dialog>
    <confirm-dialog ref="confirm_remove_memo" @ok="removeMemo()">
        <p>このメモを削除してよろしいですか？</p>
    </confirm-dialog>

    <!-- スマホ検索セレクトモーダル -->
    <ScheduleSearchModal
        v-if="form_select_search_modal"
        :modal_title="form_select_search_modal_title"
        :search_select_type="search_select_type"
        @close="form_select_search_modal = false;"
        @set_search_value="set_search_value"
    ></ScheduleSearchModal>
</template>

<script>
import InlineLoader from '@/components/tools/InlineLoader';
import ConfirmDialog from '@/components/tools/ConfirmDialog';
import FormSelect from '@/components/forms/FormSelect';
import FormInput from '@/components/forms/FormInput';
import FormInputDate from '@/components/forms/FormInputDate';
import FormInputNumber from '@/components/forms/FormInputNumber';
import FormInputTel from '@/components/forms/FormInputTel';
import FormCheckbox from '@/components/forms/FormCheckbox';
import FormTextarea from '@/components/forms/FormTextarea';
import ScheduleSearchModal from '@/components/schedule/ScheduleSearchModal';
import ReadOnly from '@/components/forms/ReadOnly.vue';
import ButtonExec from '@/components/buttons/ButtonExec';
import ButtonExecDelete from '@/components/buttons/ButtonExecDelete';
import ButtonExecUpdate from '@/components/buttons/ButtonExecUpdate';
import ButtonNormal from '@/components/buttons/ButtonNormal';
import Schedule from '@/models/entities/schedule';
import SchedulePhotographer from '@/models/entities/schedule-photographer';
import ScheduleEquipment from '@/models/entities/schedule-equipment';
import ScheduleMemo from '@/models/entities/schedule-memo';
import ScheduleFile from '@/models/entities/schedule-file';
import School from '@/models/entities/school';
import Event from '@/models/entities/event';
import File from '@/models/entities/file';
import Invitation from '@/models/entities/invitation';
import HasSeveralDays from '@/models/enums/schedule/has-several-days';
import IgnoreAlert from '@/models/enums/schedule/ignore-alert';
import IsContacted from '@/models/enums/schedule/is-contacted';
import IsUnfixed from '@/models/enums/schedule/is-unfixed';
import RainType from '@/models/enums/schedule/rain-type';
import RainTypeExceptPostpone from '@/models/enums/schedule/rain-type-except-postpone';
import ScheduleStatus from '@/models/enums/schedule/schedule-status';
import ScheduleType from '@/models/enums/schedule/schedule-type';
import UnfixedType from '@/models/enums/schedule/unfixed-type';
import IsLocked from '@/models/enums/schedule/is-locked';
import ShootingType from '@/models/enums/school/shooting-type';
import InvitationStatus from '@/models/enums/schedule/invitation-status';
import SpareSchedule from '@/models/entities/spare-schedule';
import ScheduleCarType from '@/models/entities/schedule-car-type';

export default {
    name: 'ScheduleModalForSp',
    components: {
        InlineLoader,
        ConfirmDialog,
        FormSelect,
        FormInput,
        FormInputDate,
        FormInputNumber,
        FormInputTel,
        FormCheckbox,
        FormTextarea,
        ScheduleSearchModal,
        ReadOnly,
        ButtonExec,
        ButtonExecDelete,
        ButtonExecUpdate,
        ButtonNormal
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage'
    ],
    props: {
        modelValue: {
            type: Object
        },
        modal_title: {
            type: String,
            default: "スケジュール"
        },
        modal_title_notice: {
            type: String,
            default: ""
        },
        equipments: {
            type: Array,
            default: () => ([])
        },
        employees: {
            type: Array,
            default: () => ([])
        },
        options_studio: {
            type: Array,
            default: () => ([])
        },
        options_school: {
            type: Array,
            default: () => ([])
        },
        options_employee: {
            type: Array,
            default: () => ([])
        },
        options_employee_photographer: {
            type: Array,
            default: () => ([])
        },
        is_readonly: {
            type: Boolean,
            default: false,
        }
    },
    emits: [
        'update:modelValue',
        'close',
        'remove',
        'create',
        'update',
        'openParentScheduleModal',
    ],
    data() {
        return {
            modal_loading: 0,
            event_loading: 0,
            schedule_photographer_loading: 0,
            history_loading: 0,

            shown: false,

            schedule: new Schedule(),
            original_schedule: new Schedule(),

            events: [],
            past_schedules: [],
            past_schedule_photographers: [],
            schedule_equipments: [],
            schedule_car_types: [],

            editing_memos: [],
            delete_target_memo: new ScheduleMemo(),

            display_end_date: null,

            icon: 'bi-chevron-down',
            drop_down: false,
            drop_down_text: '詳細情報を表示',
            additional_memo: '',

            rain_types: RainType.options(),
            rain_types_except_postpone: RainTypeExceptPostpone.options(),
            schedule_statuses: ScheduleStatus.options(),
            schedule_types: ScheduleType.userOptions(),
            unfixed_types: UnfixedType.options(),

            options_event: [],
            options_photographer: [],

            HasSeveralDays: HasSeveralDays,
            IgnoreAlert: IgnoreAlert,
            IsContacted: IsContacted,
            IsUnfixed: IsUnfixed,
            ScheduleStatus: ScheduleStatus,
            ScheduleType: ScheduleType,
            RainType: RainType,
            UnfixedType: UnfixedType,
            IsLocked: IsLocked,
            ShootingType: ShootingType,

            // スマホ検索セレクトモーダル
            form_select_search_modal: false,
            form_select_search_modal_title: null,
            search_select_type: null,
        }
    },
    mounted() {
        this.schedule = this.modelValue;
        this.pickScheduleEquipment();
        this.fetchScheduleHistory();
        this.supplySchedulePhotographers();
        this.setDisplayEndDate();
        this.fetchScheduleCars();
    },
    watch: {
        'schedule.school.school_id'(new_value) {
            if (new_value) {
                this.fetchEventList();
            } else {
                this.schedule.event = new Event();
                this.options_event.splice(0);
            }
        },
        'schedule.event.event_id'() {
            this.fetchScheduleHistory();
        },
        'schedule.school.shooting_type'(new_value) {
            if (new_value) {
                if (
                    this.$helper.isNud(this.schedule.schedule_id) &&
                    !this.$helper.isNud(this.schedule.school.shooting_type) &&
                    this.schedule.school.shooting_type === ShootingType.EVENT
                ) {
                    this.schedule.schedule_type = ScheduleType.EVENT_SHOOTING;
                }
            }
        },
        'schedule.schedule_date'(new_value) {
            if (new_value) {
                this.setSchedulePhotographerOptions();
            }
        },
        'display_end_date'(new_value) {
            if (new_value !== this.schedule.schedule_date) {
                this.schedule.unfixed_type = null;
            }
        },
        'schedule.is_unfixed'(new_value) {
            if (new_value === IsUnfixed.FIXED) {
                this.schedule.unfixed_type = null;
            }
        },
        'schedule.spare_schedule.is_unfixed'(new_value) {
            if (new_value === IsUnfixed.FIXED) {
                this.schedule.spare_schedule.unfixed_type = null;
            }
        }
    },
    computed: {
        // 削除済の学校か
        isDeletedSchool() {
            return this.schedule.school.is_deleted;
        },
        // 予備日の時刻のrequired条件
        isRequiredSpareTime() {
            return (
                this.schedule.rain_type === RainType.POSTPONE &&
                this.schedule.spare_schedule.schedule_date_for_input &&
                parseInt(this.schedule.spare_schedule.is_unfixed, 10) !== IsUnfixed.UNFIXED
            );
        },
        // 複数日の時刻のrequired条件
        isRequiredSeveralDaysTime() {
            return (
                this.schedule.schedule_date_for_input !== this.display_end_date &&
                parseInt(this.schedule.is_unfixed, 10) !== IsUnfixed.UNFIXED
            );
        },
        // 行事の販売を表示
        displayEventSalesType() {
            let sales_type_display = '';
            let event = this.events.find(event => event.event_id === this.schedule.event.event_id);
            if (!this.$helper.isNud(event)) {
                sales_type_display = event.sales_type_display;
            }

            return sales_type_display;
        },
        // 行事の集合写真を表示
        displayEventGroupPhotoType() {
            let group_photo_type_display = '';
            let event = this.events.find(event => event.event_id === this.schedule.event.event_id);
            if (!this.$helper.isNud(event)) {
                group_photo_type_display = event.group_photo_type_display;
            }

            return group_photo_type_display;
        }
    },
    methods: {
        // ×ボタンをおしてモーダルを閉じるときデータをもとに戻す
        cancelEdit() {
            if (this.schedule.schedule_id) {
                this.schedule = this.original_schedule;
                this.$emit('update:modelValue', this.original_schedule);
                this.original_schedule = new Schedule();
            }
            this.close();
        },
        // モーダル閉じる
        close() {
            this.$emit('close');
        },
        // 依頼済みの応援カメラマンを取得
        setSchedulePhotographerOptions() {
            this.schedule_photographer_loading++;

            this.options_photographer.splice(0);

            // 社員カメラマンを選択肢に追加
            this.options_photographer = JSON.stringify(this.options_employee_photographer);
            this.options_photographer = JSON.parse(this.options_photographer);

            // schedule_dateに入力された日付をもとに応援カメラマン招待を取得し、選択肢に追加
            this.$http.get('/invitations', {
                params: {date: this.schedule.schedule_date_for_input}
            })
            .then(response => {
                for (let row of response.data) {
                    let invitation = new Invitation(row);
                    if (invitation.invitation_status === InvitationStatus.DONE) {
                        this.options_photographer.push({key: invitation.partner.photographer_id, label: invitation.partner.photographer_name});
                    }
                }
            })
            .finally(() => {
                this.schedule_photographer_loading--;
            });
        },
        // 行事取得
        fetchEventList() {
            if (this.isDeletedSchool) {
                return;
            }

            this.event_loading++;

            this.options_event.splice(0);

            this.$http.get(`/schools/${this.schedule.school.school_id}/events`)
            .then(response => {
                for (let row of response.data) {
                    this.events.push(new Event(row));
                    this.options_event.push({key: row.event_id, label: row.event_name})
                }
            })
            .finally(() => {
                this.event_loading--;
            });
        },

        // スケジュール履歴取得
        fetchScheduleHistory() {
            if (
                !this.$helper.isNud(this.schedule.school.school_id) &&
                !this.$helper.isNud(this.schedule.event.event_id)
            ) {
                this.history_loading++;

                this.$http.get(`/schedules/histories`, {
                    school_id: this.schedule.school.school_id,
                    event_id: this.schedule.event.event_id,
                })
                .then(response => {
                    let past_schedules = response.data.past_schedules;
                    let past_schedule_photographers = response.data.schedule_photographers;

                    for (let row of past_schedules) {
                        this.past_schedules.push(new Schedule(row));
                    }
                    for (let row of past_schedule_photographers) {
                        this.past_schedule_photographers.push(new SchedulePhotographer(row));
                    }
                })
                .finally(() => {
                    this.history_loading--;
                });
            }
        },
        // その日に使われている社用車とスケジュールを持ったオブジェクトの配列を取得
        fetchScheduleCars() {
            if (!this.schedule.schedule_date_for_input) {
                return;
            }

            this.startScreenLoading();

            this.$http.get(`/schedule-cars/${this.schedule.schedule_date_for_input}`)
            .then(response => {
                this.schedule_car_types = response.data.map(function($schedule_car_type) {
                    // 自身のデータを除く
                    // $schedule_car_type.schedules = $schedule_car_type.schedules.filter(function ($schedule) {
                    //     return $schedule.schedule_id !== schedule.schedule_id;
                    // });

                    return new ScheduleCarType($schedule_car_type);
                });
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        pickScheduleEquipment() {
            for (let equipment of this.equipments) {
                let index = this.schedule.equipments.findIndex(schedule_equipment => parseInt(schedule_equipment.equipment.equipment_id, 10) === parseInt(equipment.equipment_id, 10));

                if (index === -1) {
                    let schedule_equipment = new ScheduleEquipment();
                    schedule_equipment.equipment = equipment;
                    this.schedule_equipments.push(schedule_equipment);

                } else {
                    this.schedule_equipments.push(this.schedule.equipments[index]);
                }
            }
        },
        //複数日にチェックが入る場合、rain_type === [2:延期]の場合nullにする
        setCarryOutInRainType() {
            if (parseInt(this.schedule.rain_type, 10) === RainType.POSTPONE) {
                this.schedule.rain_type = RainType.CARRY_OUT;
            }
        },
        // 今日の日付を入力する
        getToday() {
            let dt = new Date();
            let y = dt.getFullYear();
            let m = ("00" + (dt.getMonth()+1)).slice(-2);
            let d = ("00" + dt.getDate()).slice(-2);
            let result = y + "-" + m + "-" + d;

            return result;
        },
        // 確認日に今日の日付をセットする
        setCheckDate() {
            this.schedule.checked_date_for_input = this.getToday();
        },
        // 複数日かどうかで表示を変える
        setDisplayEndDate() {
            if (parseInt(this.schedule.has_several_days, 10) === HasSeveralDays.YES) {
                this.display_end_date = this.schedule.end_date;
            } else {
                this.display_end_date = this.schedule.schedule_date_for_input;
            }
        },
        // スケジュールに必要な人数に応じてSchedulePhotographerを追加する（減らすのは手動）
        supplySchedulePhotographers() {
            let schedule_photographer_length = this.schedule.photographers.length;

            if (this.schedule.photographer_number > schedule_photographer_length) {
                let diff = this.schedule.photographer_number - schedule_photographer_length;

                for (let i = 1; i <= diff; i++) {
                    let schedule_photographer = new SchedulePhotographer();
                    schedule_photographer.is_contacted = IsContacted.NO;
                    schedule_photographer.ignore_alert = IgnoreAlert.ALERT;
                    schedule_photographer.is_locked = IsLocked.NO;
                    this.schedule.photographers.push(schedule_photographer);
                }
            }
        },
        resetSchedulePhotographerCheckbox(index) {
            let schedule_photographer = new SchedulePhotographer();
            schedule_photographer.photographer = this.schedule.photographers[index].photographer;
            schedule_photographer.is_contacted = IsContacted.NO;
            schedule_photographer.ignore_alert = IgnoreAlert.ALERT;
            this.schedule.photographers[index] = schedule_photographer;
        },
        // 全社員追加(this.schedule.schedule_type === ScheduleType.OTHERSの場合のみ)
        setAllEmployees() {
            let employee_number = this.employees.length;

            this.schedule.photographer_number = employee_number;
            for (let employee of this.employees) {
                if (!this.schedule.photographers.some(schedule_photographer => schedule_photographer.photographer.photographer_id === employee.photographer_id)) {
                    let schedule_photographer = new SchedulePhotographer();
                    schedule_photographer.photographer = employee;
                    schedule_photographer.is_contacted = IsContacted.NO;
                    schedule_photographer.ignore_alert = IgnoreAlert.ALERT;
                    schedule_photographer.is_locked = IsLocked.NO;

                    this.schedule.photographers.push(schedule_photographer);
                }
            }
        },
        removeSchedulePhotographer(index) {
            this.schedule.photographers.splice(index, 1);
        },
        findPhotographerNameById(photographer_id) {
            if (this.$helper.isNud(photographer_id)) {
                return '選択なし';
            }
            let photographer = this.options_photographer.find(option => parseInt(option.key, 10) === parseInt(photographer_id, 10));

            return photographer.label;
        },
        findEventNameById(event_id) {
            if (this.$helper.isNud(event_id)) {
                return '選択なし';
            }
            let event = this.options_event.find(option => parseInt(option.key, 10) === parseInt(event_id, 10));

            return event.label;
        },
        findSchoolNameById(school_id) {
            if (this.$helper.isNud(school_id)) {
                return '選択なし';
            }
            let school = this.options_school.find(option => parseInt(option.key, 10) === parseInt(school_id, 10));

            return school.label;
        },
        //一括操作の出し入れ
        toggleDrop() {
            if (this.drop_down) {
                this.drop_down = false;
                this.icon = 'bi-chevron-down';
                this.drop_down_text = '詳細情報を表示';
            } else {
                this.drop_down = true;
                this.icon = 'bi-chevron-up'
                this.drop_down_text = '詳細情報を隠す';
            }
        },
        displayFileIcon(schedule_file) {
            let mime_type = schedule_file.file.mime_type;

            let icon = 'bi-file-earmark-medical';

            if (mime_type.slice(0, 5) === 'image') {
                icon = 'bi-file-earmark-image'

            } else if (mime_type === 'application/pdf') {
                icon = 'bi-file-earmark-pdf';
            }

            return icon;
        },
        // urlにアクセストークンをつけて返す
        generateFileUrl(schedule_file) {
            return `${process.env.VUE_APP_API_URI}/schedules/files/${schedule_file.file.file_id}?access_token=${this.$store.state.auth.access_token}`;
        },
        // ファイルのアップロード
        upload(event) {
            this.startScreenLoading();

            let formData = new FormData()
            let uploading_files = event.target.files;

            Object.entries(uploading_files).forEach(([key, value]) => {
                if (Array.isArray(value)) {
                value.forEach((v) => {
                    formData.append('file[]', v)  // arrayデータを分割して入れ直す
                })
                } else {
                formData.append(key, value)
                }
            })

            this.uploading = true;
            let headers = {
                'content-type': 'multipart/form-data'
            };
            this.$http.post(`/schedules/files`, formData, {headers})
            .then(response => {
                for (let row of response.data) {
                    let schedule_file = new ScheduleFile();
                    schedule_file.file = new File(row);
                    this.schedule.files.push(schedule_file);
                }
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        // ファイル削除
        removeScheduleFile(index) {
            this.schedule.files.splice(index, 1);
        },
        // メモの高さ自動調節
        adjustHeight(){
            const textarea = this.$refs.adjust_textarea
            const resetHeight = new Promise(function(resolve) {
            resolve(textarea.style.height = 'auto')
            });
            resetHeight.then(function(){
            textarea.style.height = textarea.scrollHeight + 'px'
            });
        },
        // 学校名のリセット
        resetSchoolInput() {
            this.schedule.school = new School();
            this.schedule.event = new Event();
        },

        /**
         * スマホ検索セレクトモーダル
         */
        // 学校
        openSchoolSelectSearchModal() {
            this.form_select_search_modal_title = '学校選択';
            this.search_select_type = 'school';
            this.form_select_search_modal = true;
        },
        // 選択された学校を表示名とスケジュールにセット
        set_search_value(target_id) {
            // 学校
            if (this.search_select_type === 'school') {
                this.schedule.school.school_name = this.options_school.find((option) => {
                    return option['key'] === parseInt(target_id, 10);
                }).label;
                this.schedule.school.school_id = target_id;
            }
            this.search_select_type = null;
        },

        setTimeZone() {
            return new Promise(resolve => {
                if (parseInt(this.schedule.has_several_days, 10) !== HasSeveralDays.YES) {
                    if (parseInt(this.schedule.is_unfixed, 10) === IsUnfixed.UNFIXED) {
                        if (this.schedule.unfixed_type === UnfixedType.AM) {
                            this.schedule.unfixed_type = UnfixedType.AM;
                            this.schedule.hour_from = 9;
                            this.schedule.minute_from = 0;
                            this.schedule.hour_to = 12;
                            this.schedule.minute_to = 0;
                        } else if (this.schedule.unfixed_type === UnfixedType.PM) {
                            this.schedule.unfixed_type = UnfixedType.PM;
                            this.schedule.hour_from = 13;
                            this.schedule.minute_from = 0;
                            this.schedule.hour_to = 15;
                            this.schedule.minute_to = 0;
                        } else if (this.schedule.unfixed_type === UnfixedType.AFTER_SCHOOL) {
                            this.schedule.unfixed_type = UnfixedType.AFTER_SCHOOL;
                            this.schedule.hour_from = 15;
                            this.schedule.minute_from = 0;
                            this.schedule.hour_to = 18;
                            this.schedule.minute_to = 0;
                        } else if (this.schedule.unfixed_type === UnfixedType.UNCERTAIN) {
                            this.schedule.unfixed_type = UnfixedType.UNCERTAIN;
                            this.schedule.hour_from = 0;
                            this.schedule.minute_from = 0;
                            this.schedule.hour_to = 24;
                            this.schedule.minute_to = 0;
                        }
                    }
                }
                resolve(true);
            });
        },
        // 複数日のときの時刻を設定する
        setHasSeveralDaysTimeZone() {
            return new Promise(resolve => {
                if (parseInt(this.schedule.has_several_days, 10) === HasSeveralDays.YES) {
                    this.schedule.hour_to = 24;
                    this.schedule.minute_to = 0;
                    if (parseInt(this.schedule.is_unfixed, 10) === IsUnfixed.UNFIXED) {
                        this.schedule.hour_from = 0;
                        this.schedule.minute_from = 0;
                        this.schedule.end_hour = 24;
                        this.schedule.end_minute = 0;
                    }
                }
                resolve(true);
            })
        },
        // 予備日の時刻を設定する
        setSpareSchedule() {
            return new Promise(resolve => {
                if (parseInt(this.schedule.rain_type, 10) === RainType.POSTPONE) {
                    if (parseInt(this.schedule.spare_schedule.is_unfixed, 10) === IsUnfixed.UNFIXED) {
                        if (this.schedule.spare_schedule.unfixed_type === UnfixedType.AM) {
                            this.schedule.spare_schedule.unfixed_type = UnfixedType.AM;
                            this.schedule.spare_schedule.hour_from = 9;
                            this.schedule.spare_schedule.minute_from = 0;
                            this.schedule.spare_schedule.hour_to = 12;
                            this.schedule.spare_schedule.minute_to = 0;
                        }
                        if (this.schedule.spare_schedule.unfixed_type === UnfixedType.PM) {
                            this.schedule.spare_schedule.unfixed_type = UnfixedType.PM;
                            this.schedule.spare_schedule.hour_from = 13;
                            this.schedule.spare_schedule.minute_from = 0;
                            this.schedule.spare_schedule.hour_to = 15;
                            this.schedule.spare_schedule.minute_to = 0;
                        }
                        if (this.schedule.spare_schedule.unfixed_type === UnfixedType.AFTER_SCHOOL) {
                            this.schedule.spare_schedule.unfixed_type = UnfixedType.AFTER_SCHOOL;
                            this.schedule.spare_schedule.hour_from = 15;
                            this.schedule.spare_schedule.minute_from = 0;
                            this.schedule.spare_schedule.hour_to = 18;
                            this.schedule.spare_schedule.minute_to = 0;
                        }
                        if (this.schedule.spare_schedule.unfixed_type === UnfixedType.UNCERTAIN) {
                            this.schedule.spare_schedule.unfixed_type = UnfixedType.UNCERTAIN;
                            this.schedule.spare_schedule.hour_from = 0;
                            this.schedule.spare_schedule.minute_from = 0;
                            this.schedule.spare_schedule.hour_to = 24;
                            this.schedule.spare_schedule.minute_to = 0;
                        }
                    }
                } else {
                    this.schedule.spare_schedule = new SpareSchedule();
                }
                resolve(true);
            });
        },
        setScheduleMemo() {
            return new Promise(resolve => {
                if (!this.$helper.isNud(this.additional_memo)) {
                    let schedule_memo = new ScheduleMemo();
                    schedule_memo.content = this.additional_memo;
                    schedule_memo.memo_date = this.getToday();
                    schedule_memo.employee.photographer_name = this.$store.state.auth.photographer_name;
                    this.schedule.memos.push(schedule_memo);
                    this.additional_memo = '';
                }
                resolve(true);
            });
        },
        // スケジュールタイプが「その他」の場合は、アラートに引っかからないようにする
        ignoreAlert() {
            return new Promise(resolve => {
                if (parseInt(this.schedule.schedule_type, 10) === ScheduleType.OTHERS) {
                    for (let schedule_photographer of this.schedule.photographers) {
                        schedule_photographer.is_contacted = IsContacted.YES;
                        schedule_photographer.ignore_alert = IgnoreAlert.IGNORE;
                    }
                }

                resolve(true);
            });
        },
        // 備品をスケジュールに紐づける
        writeScheduleEquipments() {
            return new Promise(resolve => {
                this.schedule.equipments.splice(0);

                for (let schedule_equipment of this.schedule_equipments) {
                    if (!this.$helper.isNud(parseInt(schedule_equipment.quantity, 10)) && parseInt(schedule_equipment.quantity, 10) > 0) {
                        this.schedule.equipments.push(schedule_equipment);
                    }
                }

                resolve(true);
            });
        },
        // スケジュール登録・更新時カメラマンの重複チェック
        isDuplicatePhotographer(photographers) {
            let photographer_ids = [];
            for (let schedule_photographer of photographers) {
                if (!this.$helper.isNud(schedule_photographer.photographer.photographer_id)) {
                    photographer_ids.push(schedule_photographer.photographer.photographer_id);
                }
            }
            let setPhotographerIds = new Set(photographer_ids); // ダブリ削除
            return photographer_ids.length !== setPhotographerIds.size;
        },
        // display_end_dateとschedule_dateを比較する
        setHasSeveralDays() {
            return new Promise(resolve => {
                // 複数日の場合
                if (this.display_end_date !== this.schedule.schedule_date_for_input) {
                    this.schedule.has_several_days = HasSeveralDays.YES;
                    this.schedule.end_date = this.display_end_date;
                } else { // 複数日じゃない場合
                    this.schedule.has_several_days = HasSeveralDays.NO;
                }
                resolve(true);
            });
        },
        // 時刻 From/To 複数日付 From/To のテレコチェック
        checkTimeFromTo() {
            // 時間未定の場合チェックしない
            if (this.schedule.is_unfixed === IsUnfixed.UNFIXED) return false;

            let error_message = '';
            if (this.schedule.hour_from > this.schedule.hour_to) {
                error_message = 'スケジュールの終了時刻は開始時刻より前には設定できません';
            }
            if (
                this.schedule.hour_from === this.schedule.hour_to
                && this.schedule.minute_from >= this.schedule.minute_to
            ) {
                error_message = 'スケジュールの終了時刻は開始時刻より前には設定できません';
            }

            return error_message;
        },
        checkTimeFromToForSpare() {
            // 時間未定の場合チェックしない
            if (this.schedule.spare_schedule.is_unfixed === IsUnfixed.UNFIXED) return false;

            let error_message = '';
            if (
                this.schedule.rain_type === RainType.POSTPONE &&
                this.schedule.spare_schedule.schedule_date_for_input
            ) {
                if (this.schedule.schedule_date_for_input > this.schedule.spare_schedule.schedule_date_for_input) {
                    error_message = '予備日の日付はスケジュールの日付より前には設定できません';
                }
                if (this.schedule.spare_schedule.hour_from > this.schedule.spare_schedule.hour_to) {
                    error_message = '予備日の終了時刻は予備日の開始時刻より前には設定できません';
                }
                if (
                    this.schedule.spare_schedule.hour_from === this.schedule.spare_schedule.hour_to
                    && this.schedule.spare_schedule.minute_from >= this.schedule.spare_schedule.minute_to
                ) {
                    error_message = '予備日の終了時刻は予備日の開始時刻より前には設定できません';
                }
            }

            return error_message;
        },
        checkTimeFromToForSeveralDays() {
            let error_message = '';
            if (
                this.schedule.has_several_days === HasSeveralDays.YES &&
                this.schedule.schedule_date_for_input >= this.schedule.end_date
            ) {
                error_message = '複数日最終日の日付は初日と同じ日付、または前には設定できません';
            }

            return error_message;
        },
        // 登録・更新準備
        async prepare() {
            if (this.$helper.isNud(this.schedule.event.event_id) && this.$helper.isNud(this.schedule.schedule_name)) {
                this.showErrorMessage(this.schedule.schedule_type === ScheduleType.EVENT_SHOOTING ? 'イベント名は必須です' : 'スケジュール名は必須です');
                return;
            }

            if (this.schedule.photographers.length > this.schedule.photographer_number) {
                this.showErrorMessage('登録されたカメラマンがスケジュールの必要人数を超えています');
                return;
            }

            if (this.schedule.schedule_type !== ScheduleType.OTHERS && !this.schedule.photographer_number) {
                this.showErrorMessage('カメラマンは1名以上にしてください');
                return
            }

            // カメラマン重複チェック
            if (this.isDuplicatePhotographer(this.schedule.photographers)) {
                this.showErrorMessage('カメラマンが重複しています');
                return
            }

            // 時刻 From/To 複数日付 From/To のテレコチェック
            if (this.checkTimeFromTo()) { // 本予定
                this.showErrorMessage(this.checkTimeFromTo());
                return;
            }

            if (this.checkTimeFromToForSpare()) { // 予備日
                this.showErrorMessage(this.checkTimeFromToForSpare());
                return;
            }

            if (this.checkTimeFromToForSeveralDays()) { // 複数日
                this.showErrorMessage(this.checkTimeFromToForSeveralDays());
                return;
            }

            await Promise.all([
                this.setHasSeveralDays(),
                this.setTimeZone(),
                this.setHasSeveralDaysTimeZone(),
                this.setSpareSchedule(),
                this.setScheduleMemo(),
                this.ignoreAlert(),
                this.writeScheduleEquipments(),
            ]);

            if (this.$helper.isNud(this.schedule.schedule_id)) {
                await this.createSchedule();

            } else {
                await this.updateSchedule();
            }
        },
        // 登録
        createSchedule() {
            return new Promise(resolve => {
                this.startScreenLoading();
                this.$http.post(`/schedules`, this.schedule)
                .then(response => {
                    this.schedule = new Schedule(response.data);
                    this.$emit('update:modelValue', this.schedule);
                    this.$emit('create');
                    this.$emit('close');
                    this.showMessage('登録しました');
                })
                .finally(() => {
                    this.endScreenLoading();
                    resolve(true);
                });
            });
        },
        // 更新
        updateSchedule() {
            return new Promise(resolve => {
                this.startScreenLoading();

                this.$http.put(`/schedules/${this.schedule.schedule_id}`, this.schedule)
                .then(response => {
                    this.schedule = new Schedule(response.data);
                    this.supplySchedulePhotographers();
                    this.$emit('update:modelValue', this.schedule);
                    this.$emit('update');
                    this.showMessage('更新しました');
                })
                .finally(() => {
                    this.endScreenLoading();
                    resolve(true);
                });
            })
        },
        // 削除
        removeSchedule() {
            this.startScreenLoading();

            this.$http.delete(`/schedules/${this.schedule.schedule_id}`)
            .then(() => {
                this.close();
                this.showMessage('削除しました');
                this.$emit('remove');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        // ステータスを撮影完了にする
        finishSchedule() {
            this.startScreenLoading();

            this.schedule.schedule_status = ScheduleStatus.FINISHED;

            this.$http.put(`/schedules/${this.schedule.schedule_id}/finish`)
            .then(() => {
                this.showMessage('スケジュールを撮影完了しました');
                this.$emit('update:modelValue', this.schedule);
                this.$emit('update');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        // ステータスを雨天延期にする
        postponeSchedule() {
            this.startScreenLoading();

            this.schedule.schedule_status = ScheduleStatus.POSTPONED;

            this.$http.put(`/schedules/${this.schedule.schedule_id}/postpone`)
            .then(() => {
                this.showMessage('スケジュールを雨天延期しました');
                this.$emit('update:modelValue', this.schedule);
                this.$emit('update');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        // ステータスを撮影キャンセルにする
        cancelSchedule() {
            this.startScreenLoading();

            this.schedule.schedule_status = ScheduleStatus.CANCELED;

            this.$http.put(`/schedules/${this.schedule.schedule_id}/cancel`)
            .then(() => {
                this.showMessage('スケジュールを撮影キャンセルしました');
                this.$emit('update:modelValue', this.schedule);
                this.$emit('update');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        // 本予定のモーダルを開く
        openParentScheduleModal(spare_schedule_id) {
            this.$emit('openParentScheduleModal', spare_schedule_id);
        },
        // メモ編集モードへ
        toEditMode(memo) {
            this.editing_memos.push(new ScheduleMemo(JSON.parse(JSON.stringify(memo))));
        },
        // メモノーマルモードへ
        toNormalMode(memo) {
            // 編集前のメモ内容に戻す
            const editing_index = this.editing_memos.findIndex((editing_memo) => editing_memo.schedule_memo_id === memo.schedule_memo_id);
            const before_memo = this.editing_memos[editing_index];
            if (before_memo) {
                memo.content = before_memo.content;
            }

            const index = this.schedule.memos.findIndex((original) => original.schedule_memo_id === memo.schedule_memo_id);
            this.schedule.memos.splice(index, 1, memo);

            // 編集中リストから削除
            this.editing_memos.splice(editing_index, 1);
        },
        // メモ更新
        updateMemo(memo) {
            if (this.$helper.isNud(memo.content)) {
                this.showErrorMessage('メモを空で登録することはできません。');
                return;
            }

            this.startScreenLoading();

            this.$http.put(`/schedule-memos/${memo.schedule_memo_id}`, {content: memo.content})
            .then(response => {
                // 置き換え
                const schedule_memo = new ScheduleMemo(response.data);
                const index = this.schedule.memos.findIndex((schedule_memo) => schedule_memo.schedule_memo_id === memo.schedule_memo_id);
                this.schedule.memos.splice(index, 1, schedule_memo);

                // 編集中リストから削除
                const editing_index = this.editing_memos.findIndex((editing_memo) => editing_memo.schedule_memo_id === memo.schedule_memo_id);
                this.editing_memos.splice(editing_index, 1);

                this.showMessage('更新しました。');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        // メモ削除
        removeMemo() {
            this.startScreenLoading();

            this.$http.delete(`/schedule-memos/${this.delete_target_memo.schedule_memo_id}`)
            .then(() => {
                const index = this.schedule.memos.findIndex((schedule_memo) => schedule_memo.schedule_memo_id === this.delete_target_memo.schedule_memo_id);
                this.schedule.memos.splice(index, 1);

                this.showMessage('削除しました。');
            })
            .finally(() => {
                this.delete_target_memo = new ScheduleMemo();
                this.endScreenLoading();
            });
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.school-name {
    padding: 0.35rem 0.35rem 0.35rem 0.75rem;
}
.reset-input {
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.15rem;
    background-color: #e9ecef;
    margin-left: 0.3rem;
}
</style>
