<template>
    <template v-if="current_day === null">
        <section class="sp-section">
            <div class="accordion mb-4">
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button p-2" :class="{'collapsed': accordion.collapsed}" type="button" @click="toggleDrop()">
                            <i class="bi bi-search me-1"></i>絞り込み
                        </button>
                    </h2>
                    <div class="accordion-collapse collapse" :class="{'show': accordion.show}">
                        <div class="accordion-body p-3">

                            <!-- 種類 -->
                            <div class="py-1 border-bottom">
                                <div class="py-2">種類</div>
                                <div class="d-flex justify-content-between align-items-center py-2">
                                    <div class="col-16 d-flex align-items-center">
                                        <div>{{ schedule_type_label }}</div>
                                        <div class="reset-input" style="cursor: pointer;" @click="resetScheduleTypeInput"><i class="bi bi-eraser-fill"></i></div>
                                    </div>
                                    <div class="col-8 text-end">
                                        <button-search
                                            icon=""
                                            text="選択"
                                            color="btn-link"
                                            @click="openScheduleTypeSelectSearchModal"
                                        ></button-search>
                                    </div>
                                </div>
                            </div>

                            <!-- 店舗 -->
                            <div class="py-1 border-bottom">
                                <div class="py-2">店舗</div>
                                <div class="d-flex justify-content-between align-items-center py-2">
                                    <div class="col-16 d-flex align-items-center">
                                        <div>{{ studio_name }}</div>
                                        <div class="reset-input" style="cursor: pointer;" @click="resetStudioInput"><i class="bi bi-eraser-fill"></i></div>
                                    </div>
                                    <div class="col-8 text-end">
                                        <button-search
                                            icon=""
                                            text="選択"
                                            color="btn-link"
                                            @click="openStudioSelectSearchModal"
                                        ></button-search>
                                    </div>
                                </div>
                            </div>

                            <!-- 学校 -->
                            <div class="py-1 border-bottom">
                                <div class="py-2">学校</div>
                                <div class="d-flex justify-content-between align-items-center py-2">
                                    <div class="col-16 d-flex align-items-center">
                                        <div>{{ school_name }}</div>
                                        <div class="reset-input" style="cursor: pointer;" @click="resetSchoolInput"><i class="bi bi-eraser-fill"></i></div>
                                    </div>
                                    <div class="col-8 text-end">
                                        <button-search
                                            icon=""
                                            text="選択"
                                            color="btn-link"
                                            @click="openSchoolSelectSearchModal"
                                        ></button-search>
                                    </div>
                                </div>
                            </div>

                            <!-- 社員・カメラマン -->
                            <div class="py-1 border-bottom">
                                <div class="py-2 col-10">
                                    <form-select
                                        v-model="condition.search_type"
                                        :options="search_types"
                                    ></form-select>
                                </div>
                                <div class="d-flex justify-content-between align-items-center py-2">
                                    <div class="col-14 d-flex align-items-center">
                                        <div>
                                            {{ photographer_name }}
                                        </div>
                                        <div class="reset-input" style="cursor: pointer;" @click="resetPhotographerInput"><i class="bi bi-eraser-fill"></i></div>
                                    </div>
                                    <div class="col-10 d-flex justify-content-end align-items-center">
                                        <div class="text-end me-2">
                                            <button-exec
                                                icon="bi-person-circle"
                                                text=""
                                                color="btn-link"
                                                @click="setLoginUserIdInCondition"
                                            ></button-exec>
                                        </div>
                                        <div class="text-end">
                                            <button-search
                                                icon=""
                                                text="選択"
                                                color="btn-link"
                                                @click="openEmployeeSelectSearchModal"
                                            ></button-search>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="py-3 border-bottom">
                                <div class="py-2">自由入力</div>
                                <form-input
                                    v-model="condition.schedule_name"
                                ></form-input>
                            </div>

                            <!-- 検索ボタン -->
                            <div class="col-sm-3 text-center mt-2">
                                <button-search
                                    @click="fetchCalendarList()"
                                ></button-search>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-between mb-2">
                <div class="d-flex">
                    <div class="text-end me-2">
                        <button-search
                            icon="bi-skip-backward-fill"
                            text=""
                            size="btn-sm"
                            color="btn-outline-primary"
                            :disabled="calendar_loading"
                            @click="subMonth"
                        ></button-search>
                    </div>
                    <div class="text-center align-self-center">
                        <select
                            v-model="condition.date"
                            class="form-select form-select-sm"
                            @change="fetchCalendarList()"
                        >
                            <template v-for="date in calendar_dates" :key="date">
                                <option :value="date.key">{{ date.label }}</option>
                            </template>
                        </select>
                    </div>
                    <div class="text-start ms-2">
                        <button-search
                            icon="bi-skip-forward-fill"
                            text=""
                            size="btn-sm"
                            color="btn-outline-primary"
                            :disabled="calendar_loading"
                            @click="addMonth"
                        ></button-search>
                    </div>
                </div>
                <div class="col-sm">
                    <button-search
                        icon=""
                        text="今月"
                        size="btn-sm"
                        color="btn-link"
                        :disabled="calendar_loading"
                        @click="todayMonth"
                    ></button-search>
                </div>
            </div>

            <inline-loader v-if="calendar_loading"></inline-loader>
            <div class="sp-calendar" v-else>
                <div class="sp-calendar-week">
                    <div class="sp-calendar-day text-center" style="font-size: 12px;">
                        <span>月</span>
                    </div>
                    <div class="sp-calendar-day text-center" style="font-size: 12px;">
                        <span>火</span>
                    </div>
                    <div class="sp-calendar-day text-center" style="font-size: 12px;">
                        <span>水</span>
                    </div>
                    <div class="sp-calendar-day text-center" style="font-size: 12px;">
                        <span>木</span>
                    </div>
                    <div class="sp-calendar-day text-center" style="font-size: 12px;">
                        <span>金</span>
                    </div>
                    <div class="sp-calendar-day text-center" style="font-size: 12px;">
                        <span>土</span>
                    </div>
                    <div class="sp-calendar-day text-center" style="font-size: 12px;">
                        <span>日</span>
                    </div>
                </div>
                <div class="sp-calendar-week" v-for="week in calendars" :key="week">
                    <div class="sp-calendar-day" v-for="calendar, calendar_index in week" :key="calendar" @click="setCurrentDay(calendar)">
                        <div
                            class="border-bottom"
                            :class="displayHeaderColor(calendar)"
                        >
                            <div
                                class="sp-calendar-day-head position-relative"
                                :class="generateHeaderDayColor(calendar)"
                            >
                                <!-- ダブルブッキングアイコン表示 -->
                                <div
                                    v-if="isVisibleDoubleBookingAlert(calendar)"
                                    class="text-center alert-double-bookings position-absolute top-50 translate-middle-y rounded-circle border border-secondary"
                                    style="left: 5%;"
                                >
                                    <h1 class="is-visible-alert">
                                        <i style="font-size: 10px;" class="bi bi-exclamation-lg d-flex justify-content-center align-items-center"></i>
                                    </h1>
                                </div>
                                <span
                                    class="today-color"
                                    :class="generateHeaderTodayColor(calendar, calendar_index)"
                                >
                                    {{ calendar.header_day }}
                                </span>

                                <!-- <span>{{ calendar.header_day }}</span> -->
                                <!-- 備品不足アイコン表示 -->
                                <div
                                    v-if="isVisibleEquipmentAlert(calendar)"
                                    class="text-center alert-equipments position-absolute top-50 translate-middle-y rounded-circle border border-secondary"
                                    style="right: 5%;"
                                >
                                    <h1 class="is-visible-alert">
                                        <i style="font-size: 10px;" class="bi bi-exclamation-lg d-flex justify-content-center align-items-center"></i>
                                    </h1>
                                </div>
                            </div>
                        </div>
                        <div class="sp-calendar-day-body">
                            <template v-for="schedule, schedule_index in calendar.schedules" :key="schedule">
                                <div
                                    class="sp-calendar-item"
                                    v-if="schedule_index <= 2"
                                    :class="displayScheduleColor(schedule)"
                                >
                                    {{ schedule.schedule_name_display }}
                                </div>
                            </template>
                            <div v-if="calendar.schedules.length > 3" style="font-size:12px;">...</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </template>

    <template v-else>
        <div class="d-flex justify-content-between align-items-center bg-white py-2">
            <div>
                <button-exec
                    text="追加"
                    icon="bi-calendar2-plus"
                    color="btn-link"
                    size="btn-sm"
                    style="font-size:.8rem;"
                    @click="openCreateScheduleModal(current_day.date_for_input);"
                ></button-exec>
            </div>
            <div>
                <button type="button" class="btn-close" @click="current_day = null"></button>
            </div>
        </div>
        <div class="col" :class="{'border-info': isToday(current_day)}">
            <div class="py-2">
                <div class="d-flex justify-content-center align-items-center">
                    <div class="text-end me-2">
                        <button-search
                            icon="bi-skip-backward-fill"
                            text=""
                            size="btn-sm"
                            color="btn-outline-primary"
                            :disabled="calendar_loading"
                            @click="subDay"
                        ></button-search>
                    </div>
                    <div
                        class="text-center py-2 fs-5 mx-2 align-self-center"
                        :class="generateHeaderDayColor(current_day, calendar_index)"
                    >
                        {{ current_day.full_date_display }}
                    </div>
                    <div class="text-start ms-2">
                        <button-search
                            icon="bi-skip-forward-fill"
                            text=""
                            size="btn-sm"
                            color="btn-outline-primary"
                            :disabled="calendar_loading"
                            @click="addDay"
                        ></button-search>
                    </div>
                </div>
            </div>
            <!-- 社員休暇・必要カメラマン数の表示 -->
            <div v-if="!calendar_loading" class="bg-white px-2 py-1 mb-3 d-flex justify-content-between align-items-center">
                <!-- 社員休暇ボタン -->
                <div
                    class="text-center px-1 pointer shadow-sm border rounded"
                    @click="openAbsenceModalForSp(current_day.date)"
                >
                    <i class="bi bi-person-dash"></i> <small>{{ current_day.absences.length }}</small>
                </div>
                <!-- 必要カメラマン数の表示 -->
                <div class="fs-5">
                    <i class="bi bi-camera-fill"></i> <small>{{ current_day.photographer_count }}</small>
                </div>
            </div>
            <inline-loader v-if="calendar_loading"></inline-loader>
            <div class="bg-white py-2 border" v-else>
                <template v-if="current_day.schedules.length">
                    <template v-for="schedule, schedule_index in current_day.schedules" :key="schedule">
                        <div
                            class="col-md m-2 border border-1 rounded border-dark px-1 pointer schedule-status"
                            :class="displayScheduleColor(schedule)"
                            style="font-size:.7rem;"
                            @click="
                                openUpdateScheduleModal(current_day.schedules[schedule_index]);
                            "
                        >
                            <div class="schedule-status-icon"><i class="bi" :class="schedule.schedule_status_icon"></i></div>
                            <div class="schedule-name" style="vertical-align: middle">
                                <span style="font-size:.9rem;"><i class="bi me-1" :class="schedule.schedule_icon"></i></span>
                                {{ current_day.schedules[schedule_index].schedule_name_display }}
                            </div>
                            <div class="text-end">
                                {{ displayScheduleTime(current_day.schedules[schedule_index]) }}
                            </div>
                            <div class="text-end">
                                <!-- カメラマン名の表示 -->
                                <SchedulePhotographerShortenedName
                                    :schedule="current_day.schedules[schedule_index]"
                                ></SchedulePhotographerShortenedName>
                                {{ displayCarShortenedName(current_day.schedules[schedule_index].cars) }}
                            </div>
                        </div>
                    </template>
                </template>
                <template v-else>
                    <div class="text-center py-3" style="font-size:12px;">
                        現在スケジュールはありません
                    </div>
                </template>
                <!-- <div class="text-danger text-center" style="font-size:.8rem;">
                    <i class="bi bi-exclamation-triangle"></i> 社用車不足(3/2)
                </div> -->
            </div>
        </div>
    </template>

    <!-- スケジュール詳細モーダル -->
    <ScheduleModalForSp
        v-if="schedule_modal_show"
        v-model="target_schedule"
        :modal_title="schedule_modal_title"
        :equipments="equipments"
        :employees="employees"
        :options_studio="options_studio"
        :options_school="options_school"
        :options_employee="options_employee"
        :options_employee_photographer="options_employee_photographer"
        @close="schedule_modal_show = false;"
        @remove="fetchCalendar(); fetchCalendarList(); fetchAllAlerts();"
        @create="schedule_modal_show = false; fetchCalendar(); fetchCalendarList(); fetchAllAlerts();"
        @update="fetchCalendar(); fetchCalendarList(); fetchAllAlerts();"
        @openParentScheduleModal="openParentScheduleModal"
    ></ScheduleModalForSp>

    <!-- 休暇管理モーダル -->
    <AbsenceModalForSp
        v-if="absence_modal_show"
        v-model="absence_modal_absences"
        :employees="employees"
        :schedules="absence_modal_schedules"
        :date="absence_modal_date"
        :absences_update_at="absences_update_at"
        @close="absence_modal_show = false;"
        @update="fetchCalendar(); fetchAllAlerts();"
    ></AbsenceModalForSp>

    <!-- スマホ検索セレクトモーダル -->
    <ScheduleSearchModal
        v-if="form_select_search_modal"
        :modal_title="form_select_search_modal_title"
        :search_select_type="search_select_type"
        :select_photographer_search_type="select_photographer_search_type"
        @close="form_select_search_modal = false;"
        @set_search_value="set_search_value"
    ></ScheduleSearchModal>

    <!-- 本予定確認モーダル -->
    <ScheduleModalForSp
        v-if="parent_schedule_modal_show"
        v-model="parent_schedule"
        :modal_title="parent_schedule_modal_title"
        :modal_title_notice="parent_schedule_modal_title_notice"
        :equipments="equipments"
        :cars="cars"
        :employees="employees"
        :options_school="options_school"
        :options_employee="options_employee"
        :options_employee_photographer="options_employee_photographer"
        :is_readonly="is_readonly"
        @close="parent_schedule_modal_show = false; is_readonly = false;"
    ></ScheduleModalForSp>

</template>

<script>
import InlineLoader from '@/components/tools/InlineLoader';
import FormInput from '@/components/forms/FormInput';
import FormSelect from '@/components/forms/FormSelect';
import ButtonSearch from '@/components/buttons/ButtonSearch';
import ButtonExec from '@/components/buttons/ButtonExec';
import SchedulePhotographerShortenedName from '@/components/schedule/SchedulePhotographerShortenedName';
import ScheduleModalForSp from '@/components/schedule/ScheduleModalForSp';
import AbsenceModalForSp from '@/components/schedule/AbsenceModalForSp';
import ScheduleSearchModal from '@/components/schedule/ScheduleSearchModal';
import Calendar from '@/models/entities/calendar';
import Schedule from '@/models/entities/schedule';
import Employee from '@/models/entities/employee';
import Car from '@/models/entities/car';
import Equipment from '@/models/entities/equipment';
import SearchType from '@/models/enums/schedule/search-type';
import IsHoliday from '@/models/enums/schedule/is-holiday';
import HasSeveralDays from '@/models/enums/schedule/has-several-days';
import IsPhotographer from '@/models/enums/employee/is-photographer';
import ShortageStatus from '@/models/enums/schedule/shortage-status';
import IsUnfixed from '@/models/enums/schedule/is-unfixed';
import UnfixedType from '@/models/enums/schedule/unfixed-type';
import Days from '@/models/enums/days';
import AlertDoubleBooking from '@/models/entities/alert-double-booking';
import AlertEquipment from '@/models/entities/alert-equipment';
import IsAlert from '@/models/enums/schedule/is-alert';
import ScheduleType from '@/models/enums/schedule/schedule-type';

export default {
    name: 'Sp',
    components: {
        InlineLoader,
        FormInput,
        FormSelect,
        ButtonSearch,
        ButtonExec,
        ScheduleModalForSp,
        AbsenceModalForSp,
        ScheduleSearchModal,
        SchedulePhotographerShortenedName,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {
            // loading
            loading: 0,
            calendar_loading: 0,

            calendars: [],
            schools: [],
            employees: [],
            employee_photographers: [],
            invitation_partners: [],
            other_partners: [],
            cars: [],
            equipments: [],
            current_day: null,

            accordion: {
                show: false,
                collapsed: true,
            },
            condition: {
                schedule_type_key: null,
                studio_id: null,
                school_id: null,
                search_type: SearchType.EMPLOYEE,
                photographer_id: null,
                schedule_name: null,
                date: this.getThisMonth(),
            },

            // options
            search_types: SearchType.options(),
            schedule_types: ScheduleType.userOptions(),
            options_studio: [],
            options_school: [],
            options_employee: [],
            options_search_photographer: [],
            options_employee_photographer: [],

            // Enums
            SearchType: SearchType,
            IsHoliday: IsHoliday,
            ShortageStatus: ShortageStatus,
            IsUnfixed: IsUnfixed,
            UnfixedType: UnfixedType,
            Days: Days,
            IsAlert: IsAlert,

            // アラート
            double_booking_alerts: [],
            equipment_alerts: [],

            // スケジュール詳細モーダル
            schedule_modal_show: false,
            schedule_modal_title: null,
            target_schedule: new Schedule(),
            week_index: null,
            calendar_index: null,
            schedule_index: null,

            // 本予定確認モーダル
            parent_schedule_modal_show: false,
            parent_schedule_modal_title: null,
            parent_schedule: new Schedule(),
            is_readonly: false,

            // 休暇モーダル
            absence_modal_show: false,
            absence_modal_absences: [],
            absence_modal_schedules: [],
            absence_modal_date: null,

            // スマホ検索セレクトモーダル
            form_select_search_modal: false,
            form_select_search_modal_title: null,
            select_schedule_type_label: null,
            select_studio_name: null,
            select_school_name: null,
            select_photographer_name: null,
            search_select_type: null,
            select_photographer_search_type: null,
        }
    },
    mounted() {
        this.fetchAllSchools();
        this.fetchAllEmployees();
        this.fetchAllPartners();
        this.fetchCarList();
        this.FetchEquipmentList();
        this.fetchCalendarList();
        this.fetchAllAlerts();
        this.fetchAllStudiosForOptions();
    },
    watch: {
        'condition.search_type'() {
            this.condition.photographer_id = null;
        },
    },
    computed: {
        schedule_type_label() {
            return this.select_schedule_type_label ?? '全て';
        },
        studio_name() {
            return this.select_studio_name ?? '全て';
        },
        school_name() {
            return this.select_school_name ?? '全て';
        },
        photographer_name() {
            return this.select_photographer_name ?? '全て';
        },
        calendar_dates() {
            // 一年前
            let start = new Date(this.condition.date);
            start.setDate(1);
            start.setFullYear(start.getFullYear() - 1);
            // 一年後
            let end = new Date(this.condition.date);
            end.setDate(1);
            end.setFullYear(end.getFullYear() + 1);

            // 間の日付を取得(１ヶ月間隔)
            let dates = [];
            for (let date = start; date <= end; date.setMonth(date.getMonth()+1)) {
                let target_date = new Date(date.valueOf());

                let calendar_date = {
                    key: `${target_date.getFullYear()}-${("00" + (target_date.getMonth()+1)).slice(-2)}-${("00" + target_date.getDate()).slice(-2)}`,
                    label: `${target_date.getFullYear()}年${("00" + (target_date.getMonth()+1)).slice(-2)}月`
                }
                dates.push(calendar_date);
            }

            return dates;
        },
    },
    methods: {
        //アコーディオンの出し入れ
        toggleDrop() {
            if (this.accordion.show) {
                this.accordion.show = false;
                this.accordion.collapsed = true;
            } else {
                this.accordion.show = true;
                this.accordion.collapsed = false;
            }
        },
        // 今日の日付を取得
        getToday() {
            let dt = new Date();
            let y = dt.getFullYear();
            let m = ("00" + (dt.getMonth()+1)).slice(-2);
            let d = ("00" + dt.getDate()).slice(-2);
            let result = y + "-" + m + "-" + d;

            return result;
        },
        // 今月1日を取得
        getThisMonth() {
            let dt = new Date();
            dt.setDate(1);
            let y = dt.getFullYear();
            let m = ("00" + (dt.getMonth()+1)).slice(-2);
            let d = ("00" + dt.getDate()).slice(-2);
            let result = y + "-" + m + "-" + d;

            return result;
        },
        // 今日かどうか
        isToday(calendar) {
            let calendar_date = new Date(calendar.date);
            let today = new Date();

            if (
                calendar_date.getFullYear() === today.getFullYear() &&
                calendar_date.getMonth() === today.getMonth() &&
                calendar_date.getDate() === today.getDate()
            ) {
                return true;
            }

            return false;

        },
        // 前の日へ
        subDay() {
            let date = new Date(this.current_day.date);
            date.setDate(date.getDate()-1);
            let y = date.getFullYear();
            let m = ("00" + (date.getMonth()+1)).slice(-2);
            let d = ("00" + date.getDate()).slice(-2);
            let result = y + "-" + m + "-" + d;

            this.current_day.date = result;
            this.fetchCalendar();
        },
        // 次の日へ
        addDay() {
            let date = new Date(this.current_day.date);
            date.setDate(date.getDate()+1);
            let y = date.getFullYear();
            let m = ("00" + (date.getMonth()+1)).slice(-2);
            let d = ("00" + date.getDate()).slice(-2);
            let result = y + "-" + m + "-" + d;

            this.current_day.date = result;
            this.fetchCalendar();
        },
        // 前の月へ
        subMonth() {
            let date = new Date(this.condition.date);
            date.setMonth(date.getMonth() - 1);
            let y = date.getFullYear();
            let m = ("00" + (date.getMonth()+1)).slice(-2);
            let d = ("00" + date.getDate()).slice(-2);
            let result = y + "-" + m + "-" + d;

            this.condition.date = result;
            this.fetchCalendarList();
        },
        // 次の月へ
        addMonth() {
            let date = new Date(this.condition.date);
            date.setMonth(date.getMonth() + 1);
            let y = date.getFullYear();
            let m = ("00" + (date.getMonth()+1)).slice(-2);
            let d = ("00" + date.getDate()).slice(-2);
            let result = y + "-" + m + "-" + d;

            this.condition.date = result;
            this.fetchCalendarList();
        },
        // 今月へ
        todayMonth() {
            let date = new Date();
            date.setDate(1);
            let y = date.getFullYear();
            let m = ("00" + (date.getMonth()+1)).slice(-2);
            let d = ("00" + date.getDate()).slice(-2);
            let result = y + "-" + m + "-" + d;

            this.condition.date = result;
            this.fetchCalendarList();
        },
        // 今日の日付の表示色
        generateHeaderTodayColor(calendar, index) {
            // 0:月 1:火 2:水 3:木 4:金 5:土 6:日
            if (this.isToday(calendar)) {
                if (index === 5) {
                    return 'today-color-saturday';
                }

                if (index === 6 || calendar.is_holiday === IsHoliday.YES) {
                    return 'today-color-sunday';
                }

                return 'today-color-weekday';
            }
            return '';
        },
        // カレンダーの表示色
        displayHeaderColor(calendar) {
            // カメラマン不足
            if (calendar.shortage_status === ShortageStatus.DANGER) {
                return 'alert-shortage-danger';
            }
            // カメラマン不足気味
            if (calendar.shortage_status === ShortageStatus.WARNING) {
                return 'alert-shortage-warning';
            }

            return 'bg-light'
        },
        isVisibleDoubleBookingAlert(calendar) {
            // ダブルブッキング
            let alert_double_booking = this.double_booking_alerts.findIndex(alert => {
                let calendar_date = new Date(calendar.date);
                let alert_date = new Date(alert.alert_date);

                return (
                    calendar_date.getFullYear() === alert_date.getFullYear() &&
                    calendar_date.getMonth() === alert_date.getMonth() &&
                    calendar_date.getDate() === alert_date.getDate()
                );
            })
            if (alert_double_booking > - 1) {
                return true;
            }
        },
        isVisibleEquipmentAlert(calendar) {
            // 備品不足
            let alert_equipment = this.equipment_alerts.findIndex(alert => {
                let calendar_date = new Date(calendar.date);
                let alert_date = new Date(alert.alert_date);

                return (
                    calendar_date.getFullYear() === alert_date.getFullYear() &&
                    calendar_date.getMonth() === alert_date.getMonth() &&
                    calendar_date.getDate() === alert_date.getDate()
                );
            })
            if (alert_equipment > - 1) {
                return true;
            }
        },
        // カレンダーの日付の表示色
        generateHeaderDayColor(calendar) {
            let date = new Date(calendar.date);

            if (date.getDay() === Days.SUNDAY || calendar.is_holiday === IsHoliday.YES) {
                return 'text-danger';
            }

            if (date.getDay() === Days.SATURDAY) {
                return 'text-info';
            }

            return 'text-dark';
        },
        setCurrentDay(calendar) {
            this.current_day = calendar;

            this.fetchCalendar();
        },
        // スケジュールの表示色
        displayScheduleColor(schedule) {
            if (schedule.has_first_confirm_alert === IsAlert.YES) {
                return 'alert-confirm-first';
            }
            if (schedule.has_last_confirm_alert === IsAlert.YES) {
                return 'alert-confirm-last';
            }
            if (schedule.has_spare_schedule_alert === IsAlert.YES) {
                return 'alert-spare-schedule';
            }
            if (schedule.has_unselected_photographer_schedule_alert === IsAlert.YES) {
                return 'alert-unselected';
            }
            if (schedule.has_no_contact_photographer_schedule_alert === IsAlert.YES) {
                return 'alert-contact';
            }
            if (schedule.has_status_list_alert === IsAlert.YES) {
                return 'alert-status';
            }

            return 'bg-light'
        },
        // 休暇モーダルオープン
        openAbsenceModalForSp(date) {
            this.startScreenLoading();
            this.$http.get(`/calendars/${date}`, {
                params: this.condition
            })
            .then(response => {
                this.absence_modal_absences = response.data.absences;
                this.absence_modal_schedules = response.data.schedules;
                this.absence_modal_date = response.data.date;
                this.absences_update_at = response.data.absences_update_at
            })
            .finally(() => {
                this.absence_modal_show = true;
                this.endScreenLoading();
            });
        },

        /**
         * Fetch
         */
        // 全ての学校取得
        fetchAllSchools() {
            this.loading++;

            this.$http.get('/schools/all')
            .then(response => {
                for (let row of response.data) {
                    this.options_school.push({key: row.school_id, label: row.school_name})
                }
            })
            .finally(() => {
                this.loading--;
            });
        },
        // 全ての社員取得
        fetchAllEmployees() {
            this.loading++;

            this.$http.get('/employees/all')
            .then(response => {
                for (let row of response.data) {
                    let employee = new Employee(row);
                    this.employees.push(employee);
                    this.options_employee.push({key: employee.photographer_id, label: employee.photographer_name});
                    // カメラマンの社員のみ
                    if (employee.is_photographer === IsPhotographer.PHOTOGRAPHER) {
                        this.options_search_photographer.push({key: employee.photographer_id, label: employee.photographer_name});
                        this.options_employee_photographer.push({key: employee.photographer_id, label: employee.photographer_name});
                    }
                }
            })
            .finally(() => {
                this.loading--;
            });
        },
        // 全ての応援カメラマン取得
        fetchAllPartners() {
            this.loading++;

            this.$http.get('/partners/all')
            .then(response => {
                for (let row of response.data) {
                    this.options_search_photographer.push({key: row.photographer_id, label: row.photographer_name});
                }
            })
            .finally(() => {
                this.loading--;
            });
        },
        // 店舗を取得してoptions用に整形
        fetchAllStudiosForOptions() {
            this.options_studio_loading++;

            this.$http.get('/studios')
            .then(response => {
                for (let row of response.data) {
                    this.options_studio.push({key: row.studio_id, label: row.studio_name});
                }
            })
            .finally(() => {
                this.options_studio_loading--;
            });
        },
        // 全ての社用車取得
        fetchCarList() {
            this.loading++;

            this.$http.get(`/cars`)
            .then(response => {
                for (let row of response.data) {
                    this.cars.push(new Car(row));
                }
            })
            .finally(() => {
                this.loading--;
            });
        },
        // 全ての備品取得
        FetchEquipmentList() {
            this.loading++;

            this.$http.get('/equipments')
            .then(response => {
                for (let row of response.data) {
                    this.equipments.push(new Equipment(row));
                }
            })
            .finally(() => {
                this.loading--;
            });
        },
        // カレンダー一覧取得
        fetchCalendarList() {
            this.calendar_loading++;

            this.$http.get('/calendars', {
                params: this.condition
            })
            .then(response => {
                this.calendars.splice(0);

                for (let one_week of response.data) {
                    let week = [];
                    for (let calendar of one_week) {
                        week.push(new Calendar(calendar));
                    }
                    this.calendars.push(week);
                }
            })
            .finally(() => {
                this.calendar_loading--;
            });
        },
        // カレンダー１件取得
        fetchCalendar() {
            this.calendar_loading++;

            this.$http.get(`/calendars/${this.current_day.date}`, {
                params: this.condition
            })
            .then(response => {
                this.current_day = new Calendar(response.data);
            })
            .finally(() => {
                this.calendar_loading--;
            });
        },
        // ダブルブッキングアラート取得
        fetchDoubleBookingAlerts() {

            this.double_booking_alerts.splice(0);

            this.$http.get('/alerts/double-bookings')
            .then(response => {
                for (let row of response.data) {
                    this.double_booking_alerts.push(new AlertDoubleBooking(row));
                }
            })
            .finally(() => {
            });
        },
        // 備品不足アラート取得
        fetchEquipmentAlerts() {

            this.equipment_alerts.splice(0);

            this.$http.get('/alerts/equipments')
            .then(response => {
                for (let row of response.data) {
                    this.equipment_alerts.push(new AlertEquipment(row));
                }
            })
            .finally(() => {
            });
        },
        // 全てのアラートを取得する
        fetchAllAlerts() {
            this.fetchDoubleBookingAlerts();
            this.fetchEquipmentAlerts();
        },

        /**
         * 日別詳細
         */
        // 社用車の名前を表示する
        displayCarShortenedName(car_ids) {
            let car_names;
            if (car_ids.length) {
                let filtered_cars = this.cars.filter(car => car_ids.includes(car.car_id));
                let car_name_array = filtered_cars.map((car) => {
                    return `[${car.shortened_name}]`;
                });
                car_names = car_name_array.join('');
            }

            return car_names;
        },
        // スケジュールの時刻を表示する
        displayScheduleTime(target_schedule) {
            // 時間未定
            if (target_schedule.is_unfixed === IsUnfixed.UNFIXED) {
                // AM
                if (target_schedule.unfixed_type === UnfixedType.AM) {
                    return '時間未定 AM';
                }
                // PM
                if (target_schedule.unfixed_type === UnfixedType.PM) {
                    return '時間未定 PM';
                }
                // 放課後
                if (target_schedule.unfixed_type === UnfixedType.AFTER_SCHOOL) {
                    return '時間未定 放課後';
                }
                // 不明
                if (target_schedule.unfixed_type === UnfixedType.UNCERTAIN) {
                    return '時間未定 不明';
                }
            }
            return target_schedule.schedule_time;
        },

        /**
         * スマホ検索セレクトモーダル
         */
        // 種類
        openScheduleTypeSelectSearchModal() {
            this.form_select_search_modal_title = '種類選択';
            this.search_select_type = 'schedule_type';
            this.form_select_search_modal = true;
        },
        // 店舗
        openStudioSelectSearchModal() {
            this.form_select_search_modal_title = '店舗選択';
            this.search_select_type = 'studio';
            this.form_select_search_modal = true;
        },
        // 学校
        openSchoolSelectSearchModal() {
            this.form_select_search_modal_title = '学校選択';
            this.search_select_type = 'school';
            this.form_select_search_modal = true;
        },
        // 社員
        openEmployeeSelectSearchModal() {
            // search_typeによって渡すoptionsを変える
            if (this.condition.search_type === SearchType.EMPLOYEE) {
                this.form_select_search_modal_title = '学校担当選択';
                this.select_photographer_search_type = SearchType.EMPLOYEE;
            }
            if (this.condition.search_type === SearchType.PHOTOGRAPHER) {
                this.form_select_search_modal_title = 'カメラマン選択';
                this.select_photographer_search_type = SearchType.PHOTOGRAPHER;
            }
            this.search_select_type = 'photographer';
            this.form_select_search_modal = true;
        },
        setLoginUserIdInCondition() {
            this.condition.photographer_id = this.$store.state.auth.photographer_id;
            this.select_photographer_name = this.$store.state.auth.photographer_name;
        },
        // 選択された学校を検索条件にセット
        set_search_value(target_id) {
            // 種類
            if (this.search_select_type === 'schedule_type') {
                this.condition.schedule_type_key = target_id;
                this.select_schedule_type_label = this.schedule_types.find((option) => {
                    return option['key'] === parseInt(target_id, 10);
                }).label;
            }
            // 店舗
            if (this.search_select_type === 'studio') {
                this.condition.studio_id = target_id;
                this.select_studio_name = this.options_studio.find((option) => {
                    return option['key'] === parseInt(target_id, 10);
                }).label;
                console.log(this.select_studio_name);
            }
            // 学校
            if (this.search_select_type === 'school') {
                this.condition.school_id = target_id;
                this.select_school_name = this.options_school.find((option) => {
                    return option['key'] === parseInt(target_id, 10);
                }).label;
            }

            // 社員
            if (this.search_select_type === 'photographer') {
                this.condition.photographer_id = target_id;
                // 学校担当
                if (this.condition.search_type === SearchType.EMPLOYEE) {
                    this.select_photographer_name = this.options_employee.find((option) => {
                        return option['key'] === parseInt(target_id, 10);
                    }).label;
                }

                // カメラマン
                if (this.condition.search_type === SearchType.PHOTOGRAPHER) {
                    this.select_photographer_name = this.options_search_photographer.find((option) => {
                        return option['key'] === parseInt(target_id, 10);
                    }).label;
                }
            }

            this.search_select_type = null;
        },
        resetScheduleTypeInput() {
            this.select_schedule_type_label = null;
            this.condition.schedule_type_key = null;
        },
        resetStudioInput() {
            this.select_studio_name = null;
            this.condition.studio_id = null;
        },
        resetSchoolInput() {
            this.select_school_name = null;
            this.condition.school_id = null;
        },
        resetPhotographerInput() {
            this.select_photographer_name = null;
            this.condition.photographer_id = null;
        },

        /**
         * スケジュールモーダル
         */
        // スケジュール登録モーダル
        openCreateScheduleModal(date) {
            this.target_schedule = new Schedule();
            this.target_schedule.schedule_date = date;
            this.schedule_modal_title = 'スケジュール 新規登録'
            this.schedule_modal_show = true;
        },
        // スケジュール詳細モーダル
        async openUpdateScheduleModal(target_schedule) {
            let schedule_id = target_schedule.schedule_id;

            if (
                target_schedule.first_schedule &&
                target_schedule.has_several_days === HasSeveralDays.YES &&
                'schedule_id' in target_schedule.first_schedule
            ) {
                schedule_id = target_schedule.first_schedule.schedule_id;
            }

            await this.fetchSchedule(schedule_id);
            this.schedule_modal_show = true;
            this.schedule_modal_title = 'スケジュール 詳細';
        },
        // モーダル用にスケジュールを一件取得
        fetchSchedule(schedule_id) {
            return new Promise(resolve => {
                this.target_schedule = new Schedule();

                this.startScreenLoading();

                this.$http.get(`/schedules/${schedule_id}`)
                .then(response => {
                    this.target_schedule = new Schedule(response.data);
                })
                .finally(() => {
                    this.endScreenLoading();
                    resolve(true);
                });
            });
        },
        // ターゲットのスケジュールのインデックスを取得
        setScheduleIndexes(week_index = null, calendar_index = null, schedule_index = null) {
            this.week_index = week_index;
            this.calendar_index = calendar_index;
            this.schedule_index = schedule_index;
        },
        // ターゲットのスケジュールを削除する
        deleteTargetSchedule() {
            this.calendars[this.week_index][this.calendar_index].schedules.splice(this.schedule_index, 1);
            this.setScheduleIndexes()
        },

        // 本予定モーダルオープン
        async openParentScheduleModal(spare_schedule_id) {
            await this.fetchParentSchedule(spare_schedule_id);
            this.is_readonly = true;
            this.parent_schedule_modal_show = true;
            this.parent_schedule_modal_title = '本予定詳細';
            this.parent_schedule_modal_title_notice = '※編集不可';
        },
        fetchParentSchedule(spare_schedule_id) {
            return new Promise(resolve => {
                this.parent_schedule = new Schedule();

                this.startScreenLoading();

                this.$http.get(`/schedules/${spare_schedule_id}`)
                .then(response => {
                    this.parent_schedule = new Schedule(response.data.parent_schedule);
                })
                .finally(() => {
                    this.endScreenLoading();
                    resolve(true);
                });
            });
        },
    },
}
</script>

<style scoped>
.sp-calendar {border-left: 1px solid #CCC; border-top: 1px solid #CCC;}
.sp-calendar-week {display: flex;}
.sp-calendar-day {width: 14.28%; border-right: 1px solid #CCC; border-bottom: 1px solid #CCC;}
.sp-calendar-day-head{font-size: 12px; text-align: center;}
.sp-calendar-day-body {padding: 0.1rem 0.1rem 0.3rem; min-height: 5rem;}
.sp-calendar-item {width: 100%; overflow: hidden; border: 1px solid var(--bs-dark); border-radius: 0.2rem; padding: 0 0.1rem; margin-bottom: 0.1rem; font-size: 10px; white-space: nowrap;}
.schedule-name {overflow: hidden; white-space: nowrap; width: 100%; text-overflow: ellipsis;}
.schedule-status {position: relative;}
.schedule-status-icon {position: absolute; font-size: 2.8rem; left: 50%; top: 50%; transform: translate(-50%, -50%); opacity: 0.15;}
.reset-input {
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.15rem;
    background-color: #e9ecef;
    margin-left: 0.3rem;
}
.is-visible-alert {
    margin: 0;
    width: .6rem;
    height: .6rem;
    color: #000;
}
.display-position-equipment-alert {
    left: 20%;
}
.today-color {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
}
.today-color-weekday {
    background-color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
    font-size: 11px;
    color: #fff;
}
.today-color-saturday {
    background-color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
    font-size: 11px;
    color: #fff;
}
.today-color-sunday {
    background-color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
    font-size: 11px;
    color: #fff;
}
</style>
