<template>
    <transition name="fade">
        <div>
            <div class="modal-backdrop show"></div>
            <div class="modal" tabindex="-1" style="display:block">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">
                                {{ modal_title }}
                            </h5>
                            <button type="button" class="btn-close" @click="close"></button>
                        </div>

                        <!-- 検索欄 -->
                        <div class="p-3 border-bottom">
                            <div class="d-flex align-items-center justify-content-between">
                                <div
                                    v-if="search_select_type === 'schedule_type'"
                                    class="d-flex align-items-center input-group"
                                    style="width: 74%;"
                                >
                                    <form-input
                                        v-model="condition.schedule_type_label"
                                    ></form-input>
                                    <div class="input-group-text" style="cursor: pointer;" @click="resetInput"><i class="bi bi-eraser-fill"></i></div>
                                </div>
                                <div
                                    v-else-if="search_select_type === 'studio'"
                                    class="d-flex align-items-center input-group"
                                    style="width: 74%;"
                                >
                                    <form-input
                                        v-model="condition.studio_name"
                                    ></form-input>
                                    <div class="input-group-text" style="cursor: pointer;" @click="resetInput"><i class="bi bi-eraser-fill"></i></div>
                                </div>
                                <div
                                    v-else-if="search_select_type === 'school'"
                                    class="d-flex align-items-center input-group"
                                    style="width: 74%;"
                                >
                                    <form-input
                                        v-model="condition.school_name"
                                    ></form-input>
                                    <div class="input-group-text" style="cursor: pointer;" @click="resetInput"><i class="bi bi-eraser-fill"></i></div>
                                </div>
                                <div
                                    v-else-if="search_select_type === 'photographer'"
                                    class="d-flex align-items-center input-group"
                                    style="width: 74%;"
                                >
                                    <form-input
                                        v-model="condition.photographer_name"
                                    ></form-input>
                                    <div class="input-group-text" style="cursor: pointer;" @click="resetInput"><i class="bi bi-eraser-fill"></i></div>
                                </div>
                                <div class="ms-2">
                                    <button-search
                                        @click="search()"
                                    ></button-search>
                                </div>
                            </div>
                        </div>

                        <div class="modal-body pt-0">
                            <inline-loader v-if="loading"></inline-loader>

                            <!-- 選択肢リスト -->
                            <template v-else v-for="option in options" :key="option">
                                <div class="d-flex justify-content-between align-items-center border-bottom py-1">
                                    <div class="my-2">
                                        {{ option.label }}
                                    </div>
                                    <div class="my-2">
                                        <button-search
                                            icon=""
                                            text="選択"
                                            size="btn-sm"
                                            color="btn-outline-primary"
                                            @click="setValue(option.key)"
                                        ></button-search>
                                    </div>
                                </div>
                            </template>

                            <!-- ページネーション -->
                            <paginator :meta="paginator" @move="search" class="mt-3"></paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import ButtonSearch from '@/components/buttons/ButtonSearch';
import FormInput from '@/components/forms/FormInput';
import InlineLoader from '@/components/tools/InlineLoader';
import SearchType from '@/models/enums/schedule/search-type';
import IsPhotographer from '@/models/enums/employee/is-photographer';
import Paginator from '@/components/tools/Paginator';
import ScheduleType from '@/models/enums/schedule/schedule-type';

export default {
    name: 'ScheduleSearchModal',
    components: {
        ButtonSearch,
        FormInput,
        InlineLoader,
        Paginator,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    props: {
        modal_title: {
            type: String,
            default: ""
        },
        search_select_type: {
            type: String,
            default: ""
        },
        select_photographer_search_type: {
            type: Number,
            default: 0
        },
    },
    emits: [
        'close',
        'set_search_value',
    ],
    data() {
        return {
            loading: 0,
            options: [],
            paginator: {},

            // 選択肢リスト内検索
            condition: {
                school_name: null,
                photographer_name: null,
                page: 1,
            },

            // Enums
            SearchType: SearchType,
            IsPhotographer: IsPhotographer,
        }
    },
    mounted() {
        this.setConditions();
    },
    watch: {
    },
    computed: {
    },
    methods: {
        close() {
            this.$emit('close');
        },
        setValue(target_index) {
            this.$emit('set_search_value', target_index);
            this.close();
        },

        /**
         * 検索処理
         */
        search(page = 1) {
            // 種類を検索
            if (this.search_select_type === 'schedule_type') {
                this.FetchScheduleTypeList(page);
            }
            // 店舗を検索
            if (this.search_select_type === 'studio') {
                this.FetchStudioList(page);
            }
            // 学校を検索
            if (this.search_select_type === 'school') {
                this.FetchSchoolList(page);
            }
            // 社員orカメラマンを検索
            if (this.search_select_type === 'photographer') {
                this.FetchPhotographerList(page);
            }
        },
        FetchScheduleTypeList(page = 1) {
            this.condition.page = page;

            // 表示データ初期化
            this.options.splice(0);

            this.options = ScheduleType.userOptions();

            // 検索条件を保存
            this.$store.commit('condition/setSearchCondition', {
                page: 'ScheduleSearchModal',
                condition: this.condition
            });
        },
        FetchStudioList(page = 1) {
            this.loading++;
            this.condition.page = page;

            // 表示データ初期化
            this.options.splice(0);

            this.$http.get('/studios')
            .then(response => {
                for (let row of response.data) {
                    this.options.push({key: row.studio_id, label: row.studio_name});
                }

                // ページネーション用
                this.paginator = response.data.meta;
                // 検索条件を保存
                this.$store.commit('condition/setSearchCondition', {
                    page: 'ScheduleSearchModal',
                    condition: this.condition
                });
            })
            .finally(() => {
                this.loading--;
            })
        },
        FetchSchoolList(page = 1) {
            this.loading++;
            this.condition.page = page;

            // 表示データ初期化
            this.options.splice(0);

            this.$http.get('/schools', {
                params: this.condition
            })
            .then(response => {
                for (let row of response.data.data) {
                    this.options.push({key: row.school_id, label: row.school_name});
                }

                // ページネーション用
                this.paginator = response.data.meta;
                // 検索条件を保存
                this.$store.commit('condition/setSearchCondition', {
                    page: 'ScheduleSearchModal',
                    condition: this.condition
                });
            })
            .finally(() => {
                this.loading--;
            })
        },
        FetchPhotographerList(page = 1) {
            this.loading++;
            this.condition.page = page;
            this.options.splice(0);

            this.$http.get('/employees', {
                params: this.condition
            })
            .then(response => {
                for (let row of response.data.data) {
                    // 学校担当
                    if (this.select_photographer_search_type === SearchType.EMPLOYEE) {
                        this.options.push({key: row.photographer_id, label: row.photographer_name});
                    }

                    // カメラマン
                    if (this.select_photographer_search_type === SearchType.PHOTOGRAPHER) {
                        if (row.is_photographer === IsPhotographer.PHOTOGRAPHER) {
                            this.options.push({key: row.photographer_id, label: row.photographer_name});
                        }
                    }
                }

                // ページネーション用
                this.paginator = response.data.meta;
                // 検索条件を保存
                this.$store.commit('condition/setSearchCondition', {
                    page: 'ScheduleSearchModal',
                    condition: this.condition
                });
            })
            .finally(() => {
                this.loading--;
            });
        },
        setConditions () {
            this.condition = this.$store.getters['condition/merge']('ScheduleSearchModal', this.condition);
            this.search();
        },

        resetInput() {
            this.condition.school_name = null;
            this.condition.photographer_name = null;
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
